import { Button, CircularProgress } from "@mui/material";

interface ISubmitButtonProps {
  assignment_id: string;
  savingId: string;
  setSavingId: (id: string) => void;
  refetch: () => void;
  updateStatus: (assignment_id: string) => void;
}

const SubmitButton = ({
  assignment_id,
  savingId,
  updateStatus,
}: ISubmitButtonProps) => {
  return (
    <Button
      variant="contained"
      color="success"
      onClick={() => {
        updateStatus(assignment_id);
      }}
      style={{ width: "135px" }}
      disabled={savingId == assignment_id}
    >
      {savingId == assignment_id ? (
        <CircularProgress
          style={{
            color: "#3f51b5",
            width: "20px",
            height: "20px",
          }}
        />
      ) : (
        "Submit"
      )}
    </Button>
  );
};
export default SubmitButton;
