import { clone } from "lodash";
import lithClient from "./instance";

export function getTags() {
  try {
    return lithClient
      .get(`/tags`)
      .then((res) => res.data)
      .catch((err) => Promise.reject({ ...err }));
  } catch (e) {
    console.log(e);
  }
}

export function createTag(payload) {
  try {
    const tag = clone(payload);
    delete tag.id;
    return lithClient
      .post(`/tags`, tag)
      .then((res) => res.data)
      .catch((err) => Promise.reject({ ...err }));
  } catch (e) {
    console.log(e);
  }
}

export function addDocTag(doc_id: string, tag_id: string) {
  try {
    return lithClient
      .post(`/documents/${doc_id}/tags/${tag_id}`)
      .then((res) => res.data)
      .catch((err) => Promise.reject({ ...err }));
  } catch (e) {
    console.log(e);
  }
}

export function removeDocTag(doc_id: string, tag_id: string) {
  try {
    return lithClient
      .delete(`/documents/${doc_id}/tags/${tag_id}`)
      .then((res) => res.data)
      .catch((err) => Promise.reject({ ...err }));
  } catch (e) {
    console.log(e);
  }
}
