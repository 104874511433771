import lithClient from "./instance";

export const getDocTypes = async () => {
  try {
    return lithClient
      .get(`/document/types`)
      .then((res) => res.data)
      .catch((err) => Promise.reject({ ...err }));
  } catch (e) {
    console.log(e);
  }
};

export const getPageTypes = async () => {
  try {
    return lithClient
      .get(`/document/pages/types`)
      .then((res) => res.data)
      .catch((err) => Promise.reject({ ...err }));
  } catch (e) {
    console.log(e);
  }
};

export const classifyDoc = async (doc_id, classification, reclassify) => {
  return lithClient
    .post(`/documents/${doc_id}/classify?force=${reclassify}`, {
      type_id: classification,
    })
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err.response }));
};

export const classifyPage = async (
  doc_id,
  page_id,
  classification,
  reclassify
) => {
  return lithClient
    .post(
      `/documents/${doc_id}/pages/${page_id}/classify?force=${reclassify}`,
      {
        type_id: classification,
      }
    )
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err.response.data }));
};

export const classifyPageFromFeedback = async (
  doc_id,
  page_id,
  classification,
  reclassify
) => {
  return lithClient
    .put(`/documents/${doc_id}/pages/${page_id}/classify?force=${reclassify}`, {
      type_id: classification,
    })
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err.response.data }));
};
