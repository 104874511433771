import { CircularProgress, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Flex from "styled-flex-component";
import {
  InsightsReadOnlyContext,
  InsightsReadOnlyContextType,
} from "../../../pages/InsightsReadOnlyPage/context/insightsReadOnlyContext";
import { Text } from "../../UI/Typography";
import { getDocumentFeedback } from "../../../api/feedback.api";
import { isEmpty } from "lodash";
import { getDocument } from "../../../api/files.api";

const DocumentFeedbackSelector = () => {
  const { packetData } = useContext(
    InsightsReadOnlyContext
  ) as InsightsReadOnlyContextType;

  const [feedback, setFeedback] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [document, setDocument] = useState<any>();

  useEffect(() => {
    setIsLoading(true);
    getDocument(packetData.doc_id).then((res) => {
      setDocument(res);
    });
    getDocumentFeedback(packetData.doc_id).then((res) => {
      setFeedback(res.filter((feedback) => feedback.status == null));
      setIsLoading(false);
    });
  }, []);

  return (
    <Flex
      style={{
        border: isEmpty(feedback) ? "1px solid white" : "2px dashed orange",
        borderRadius: "40px",
        paddingRight: isEmpty(feedback) ? "10px" : "0px",
        marginLeft: "10px",
        minWidth: "200px",
        height: "45px",
      }}
      alignCenter
      justifyCenter
    >
      {isLoading ? (
        <CircularProgress
          style={{
            color: "whitesmoke",
            width: "20px",
            height: "20px",
          }}
        />
      ) : (
        <Tooltip
          title={
            isEmpty(feedback)
              ? ""
              : `Suggestion to change the classification to 
              ${feedback[0]?.doc_type_name} is under review.`
          }
        >
          <div
            style={{
              cursor: "default",
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              color="white"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {document?.document_types?.name}
            </Text>
          </div>
        </Tooltip>
      )}
    </Flex>
  );
};

export default DocumentFeedbackSelector;
