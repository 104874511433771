import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
} from "@mui/material";
import { Text } from "../../UI/Typography";
import Flex from "styled-flex-component";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DocumentTypeChip from "../../UI/DocumentTypeChip/DocumentTypeChip";

interface Props {
  item: any;
  itemType: string;
  exportIds: string[];
  setExportIds: (ids: string[]) => void;
}

export default function ExportTableItem({
  item,
  itemType,
  exportIds,
  setExportIds,
}: Props) {
  const updateIds = (isChecked) => {
    let updatedIds = exportIds.filter((id) => id != item.doc_id);
    if (isChecked) updatedIds = exportIds.concat(item.doc_id);
    setExportIds(updatedIds);
  };

  const pageGroupRow = () => (
    <Accordion elevation={0} key={item.doc_id}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ height: "60px", paddingLeft: 0, paddingRight: 0 }}
      >
        <Flex style={{ gap: "20px" }} alignCenter>
          <Checkbox
            checked={exportIds.includes(item.doc_id)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              updateIds(e.target.checked);
            }}
          />
          <Text customFontSize={1}>{item.doc_name}</Text>
        </Flex>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        {item.pages?.map((page, index) => (
          <Flex
            alignCenter
            style={{
              gap: "20px",
              padding: "10px",
              background: index % 2 == 0 ? "whitesmoke" : "white",
              height: "50px",
            }}
            key={index}
          >
            <Text
              customFontSize={1}
              style={{ width: "100px", marginLeft: "60px" }}
            >
              Page {page.page_index}
            </Text>
            <div style={{ width: "400px" }}>
              <DocumentTypeChip
                label={`${page.type_code} - ${page.type_name}`}
                isPrimary={true}
                maxWidth={"600px"}
              />
            </div>
            <Text>{page.score}</Text>
          </Flex>
        ))}
      </AccordionDetails>
    </Accordion>
  );

  const documentRow = () => {
    return (
      <Flex
        alignCenter
        style={{
          height: "60px",
          paddingLeft: 0,
          paddingRight: 0,
          borderBottom: "1px solid gainsboro",
        }}
      >
        <Checkbox
          checked={exportIds.includes(item.doc_id)}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(e) => {
            e.stopPropagation();
            updateIds(e.target.checked);
          }}
        />
        <Text>{item.name}</Text>
      </Flex>
    );
  };

  return itemType == "classifications" ? pageGroupRow() : documentRow();
}
