import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { supabase } from "../../utils/supabase";
import { Text } from "../../components/UI/Typography";
import { Theme } from "../../theme";
import Flex from "styled-flex-component";
import { useEffect } from "react";
import { saveUserSessionData } from "../../utils/userSession";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) saveUserSessionData(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        saveUserSessionData(session);
        navigate("/");
      } else {
        navigate("/login");
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <Flex justifyCenter alignCenter style={{ height: "100vh" }}>
      <div style={{ width: "30%" }}>
        <Flex>
          <Text
            customFontSize={5}
            customletterSpacing={1.8}
            color={Theme.secondary}
            style={{ width: "100%", textAlign: "center" }}
          >
            LITH
          </Text>
        </Flex>
        <Auth
          supabaseClient={supabase}
          appearance={{ theme: ThemeSupa }}
          providers={[]}
          showLinks={false}
        />
      </div>
    </Flex>
  );
};

export default LoginPage;
