import styled from "styled-components";

interface INavBarStyled {}

export default styled<INavBarStyled>("header")`
  position: fixed;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  background: white;
  box-shadow: 0px 2px 4px rgba(16, 35, 77, 0.06);
  top: 0;
  height: 45px;
  width: 100%;
  z-index: 100;
`;
