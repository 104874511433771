import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import Flex from "styled-flex-component";
import { Text } from "../../UI/Typography";

interface ISubmitAllButtonProps {
  submitPage: (assignment_id) => void;
  pages: any;
}

const SubmitAllButton = ({ submitPage, pages }: ISubmitAllButtonProps) => {
  const [submitCount, setSubmitCount] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const submitAll = () => {
    setIsSubmitting(true);
    pages.forEach((page) => {
      if (page.page_type == null) return;

      submitPage(page.assignment_id);
      setSubmitCount(submitCount + 1);
    });
  };
  return (
    <Flex>
      {isSubmitting ? (
        <Flex>
          <CircularProgress
            style={{
              color: "gray",
              width: "20px",
              height: "20px",
              marginRight: "10px",
            }}
          />
          <Text color="gray">
            {`Submitting... ${submitCount} of ${pages.length}`}
          </Text>
        </Flex>
      ) : (
        <Button onClick={() => submitAll()} color="success" variant="contained">
          Submit All
        </Button>
      )}
    </Flex>
  );
};
export default SubmitAllButton;
