import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import Flex from "styled-flex-component";
import { Text } from "../../UI/Typography";

interface IUnassignAllButtonProps {
  unassignPage: (assignment_id, doc_id) => void;
  document: any;
  pages: any;
}

const UnassignAllButton = ({
  document,
  unassignPage,
  pages,
}: IUnassignAllButtonProps) => {
  const [unassignCount, setUnassignCount] = useState<number>(0);
  const [isUnassigning, setIsUnassigning] = useState<boolean>(false);

  const unassignAll = () => {
    setIsUnassigning(true);
    pages.forEach((page) => {
      if (page.page_type) return;

      unassignPage(page.assignment_id, document.assignment_id);
      setUnassignCount(unassignCount + 1);
    });
  };

  return (
    <Flex>
      {isUnassigning ? (
        <Flex>
          <CircularProgress
            style={{
              color: "gray",
              width: "20px",
              height: "20px",
              marginRight: "10px",
            }}
          />
          <Text color="gray">
            {`Unassigning... ${unassignCount} of ${pages.length}`}
          </Text>
        </Flex>
      ) : (
        <Button onClick={() => unassignAll()}>Unassign All</Button>
      )}
    </Flex>
  );
};
export default UnassignAllButton;
