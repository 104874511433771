import { Text } from "../../Typography";
import Flex from "styled-flex-component";

interface ILoadingProps {
  message: string | React.ReactNode;
}

const Empty = ({ message }: ILoadingProps) => {
  if (typeof message === "string") <Text>{message}</Text>;

  return (
    <Flex justifyCenter style={{ margin: "50px" }}>
      {message}
    </Flex>
  );
};

export default Empty;
