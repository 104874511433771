import { IHighlight } from "./types";

export function getClauseHighlights(
  insights: any,
  selectedInsight,
  selectedRegion
) {
  if (!insights) return [];

  const highlights: IHighlight[] = [];

  for (const clause of insights) {
    const pageIndex: number = clause.page_index;
    const pageBboxes: any = clause.bboxes ?? [];

    // Handle old bbox format
    //TODO: Remove block once all documents have been migrated to the new bboxes:[[]] format
    if (typeof pageBboxes === "object" && Array.isArray(pageBboxes) == false) {
      const left = pageBboxes.left;
      const top = pageBboxes.top;
      const width = pageBboxes.width;
      const height = pageBboxes.height;
      highlights.push({
        pageIndex,
        height,
        left,
        top,
        width,
        text: clause.text,
        name: clause.clause_name,
        isSelected: false,
      });
      continue;
    }

    if (!(pageBboxes && pageBboxes.length)) {
      continue;
    }

    pageBboxes.map((bboxArr) => {
      if (bboxArr.length) {
        const left = bboxArr[0];
        const top = bboxArr[1];
        const width = bboxArr[2];
        const height = bboxArr[3];
        highlights.push({
          pageIndex,
          height,
          left,
          top,
          width,
          text: clause.text,
          name: clause.clause_name,
          isSelected: false,
        });
      }
    });
  }

  if (selectedRegion) {
    selectedRegion.bboxes.map((bbox) => {
      highlights.push({
        pageIndex: bbox.pageIndex,
        height: bbox.height,
        left: bbox.left,
        top: bbox.top,
        width: bbox.width,
        text: selectedRegion.text,
        isEdited: true,
        name: selectedInsight,
        isSelected: false,
      });
    });
  }

  //scroll to the clause
  const list = window.document.getElementById("clauses-list");
  if (list) {
    const index = insights.findIndex(
      (clause) => clause.clause_name === selectedInsight
    );
    if (index !== -1) {
      list.scrollTo({
        behavior: "smooth",
        top: index * 85,
      });
    }
  }

  return highlights;
}
