import {
  RenderPage,
  RenderPageProps,
  SpecialZoomLevel,
  Viewer,
  Worker,
} from "@react-pdf-viewer/core";
import { useContext, useEffect, useState } from "react";
import Container from "../../UI/Common/Container";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  GetInsightsContext,
  GetInsightsContextType,
} from "../../../pages/GetInsights/context/getInsightsContext";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  // RenderThumbnailItemProps,
  thumbnailPlugin,
} from "@react-pdf-viewer/thumbnail";
import Flex from "styled-flex-component";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { Text } from "../../UI/Typography";
import {
  CircularProgress,
  IconButton,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import { Clear, Edit, Save } from "@mui/icons-material";
import { isEmpty } from "lodash";
import { createFeedback } from "../../../api/feedback.api";
import SearchTypeField from "../../UI/Common/SearchTypeField";
interface ViewerProps {
  initialPage: number;
  refetchFeedback: () => void;
  fileUrl: string;
}

const DeafultLayoutPDFViewer = ({
  initialPage,
  refetchFeedback,
  fileUrl,
}: ViewerProps) => {
  const {
    setNumPages,
    setIsDocumentLoaded,
    setCurrentPage,
    pages,
    feedbacks,
    pageTypeOptions,
    packetData,
    backToPageTrigger,
  } = useContext(GetInsightsContext) as GetInsightsContextType;
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToNextPage, jumpToPreviousPage, jumpToPage } =
    pageNavigationPluginInstance;
  const [changingPage, setChangingPage] = useState(-1);
  const [hoveredPage, setHoveredPage] = useState(-1);
  const [pagesForFeedback, setPagesForFeedback] = useState<any>();
  const [selectedType, setSelectedType] = useState("placeholder");
  const [savingFeedbackPage, setSavingFeedbackPage] = useState(-1);
  const [currPage, setCurrPage] = useState(-1);
  const [feedbackedPages, setFeedbackedPages] = useState<any>([]);
  const [searchKey, setSearchKey] = useState("");
  const [typeOptions, setTypeOptions] = useState<any>([]);

  useEffect(() => {
    setCurrentPage(initialPage);
    setCurrPage(initialPage);
    jumpToPage(initialPage - 1);
  }, [initialPage, backToPageTrigger]);

  useEffect(() => {
    setTypeOptions(pageTypeOptions);
    if (pages)
      setPagesForFeedback(
        pages
          .filter((page) => page.type_name != null)
          .map((page) => page.page_index)
      );
  }, [pages]);

  useEffect(() => {
    const filteredTypeOptions = pageTypeOptions.filter((option) =>
      option.name.toLowerCase().includes(searchKey.toLowerCase())
    );
    setTypeOptions(filteredTypeOptions);
  }, [searchKey]);

  useEffect(() => {
    if (feedbacks) setFeedbackedPages(feedbacks);
  }, [feedbacks]);

  const renderPage: RenderPage = (props: RenderPageProps) => (
    <>
      {props.canvasLayer.children}
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
          zIndex: 100,
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            background:
              currPage == 0
                ? "none"
                : props.pageIndex == currPage - 1
                  ? "none"
                  : "rgba(190, 190, 190, 0.7)",
          }}
        >
          {!isEmpty(pagesForFeedback) &&
          pagesForFeedback.includes(props.pageIndex + 1)
            ? suggestChangeOverlay(props.pageIndex + 1)
            : noClassificationOverlay(props.pageIndex + 1)}
        </div>
      </div>
      {props.annotationLayer.children}
      {props.textLayer.children}
    </>
  );

  window.addEventListener("keydown", function (event) {
    if ((event.ctrlKey || event.metaKey) && event.code == "ArrowDown") {
      jumpToNextPage();
    }
    if ((event.ctrlKey || event.metaKey) && event.code == "ArrowUp") {
      jumpToPreviousPage();
    }
    if (event.code == "Escape") {
      jumpToPage(initialPage - 1);
    }
  });

  const suggestChangeOverlay = (page_number) => {
    const isChanging = page_number == changingPage;
    const isHovered = page_number == hoveredPage;
    const activeElement = isHovered || isChanging;
    const pageData = pages.find((page: any) => page.page_index == page_number);
    const feedback = feedbackedPages.find(
      (feedback) => feedback.page_id == pageData.page_id
    );

    return (
      <div
        style={{
          top: 0,
          width: "1%",
          margin: "20px",
        }}
        key={page_number}
        onMouseEnter={() => {
          if (page_number != currPage) setHoveredPage(page_number);
        }}
        onMouseLeave={() => setHoveredPage(-1)}
      >
        <div
          style={{
            border: feedback
              ? "3px dashed orange"
              : activeElement
                ? "3px dashed gray"
                : "2px solid gray",
            background: feedback
              ? "whitesmoke"
              : activeElement
                ? "whitesmoke"
                : "white",
            borderRadius: "10px",
            minHeight: "50px",
            paddingRight: "15px",
            paddingLeft: "15px",
            paddingTop: "10px",
            paddingBottom: "10px",
            gap: "10px",
            display: "table",
            width: "1%",
          }}
        >
          <Flex
            alignCenter
            style={{
              gap: "10px",
              height: "50px",
              whiteSpace: "nowrap",
            }}
          >
            <Text customFontSize={1}>Page {page_number}:</Text>
            {isChanging && !feedback ? (
              <Select
                onChange={(e) => {
                  setSelectedType(e.target.value);
                }}
                onClose={() => setSearchKey("")}
                value={selectedType}
                disableUnderline={true}
                sx={{
                  height: "45px",
                  background: "white",
                  borderRadius: "30px",
                  width: "250px",
                  paddingLeft: "10px",
                  paddingBottom: "15px",
                  justifyContent: "center",
                  justifySelf: "center",
                  paddingTop: "13px",
                }}
              >
                <ListSubheader
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <SearchTypeField
                    searchKey={searchKey}
                    setSearchKey={setSearchKey}
                  />
                </ListSubheader>
                <MenuItem
                  key={-1}
                  value="placeholder"
                  style={{ display: "none" }}
                >
                  Select item type
                </MenuItem>
                {typeOptions
                  .filter((option) => option != pageData.type_name)
                  .map((option: any, index) => (
                    <MenuItem
                      key={index}
                      value={option.name}
                      style={{
                        display: option.name == selectedType ? "none" : "block",
                      }}
                    >
                      {option.code == "None"
                        ? option.name
                        : `${option.code} - ${option.name}`}
                    </MenuItem>
                  ))}
              </Select>
            ) : (
              <Flex alignCenter style={{ gap: "10px" }}>
                <Text customFontSize={1}>
                  {pageData.type_code == "None"
                    ? pageData.type_name
                    : `${pageData.type_code} - ${pageData.type_name}`}
                </Text>
                <Text
                  style={{
                    background: "whitesmoke",
                    padding: "3px",
                    borderRadius: "5px",
                  }}
                >
                  {pageData.score}
                </Text>
              </Flex>
            )}
            {isHovered && !isChanging && !feedback && (
              <IconButton onClick={() => setChangingPage(page_number)}>
                <Edit />
              </IconButton>
            )}
            {isChanging && !feedback && (
              <Flex>
                <IconButton
                  onClick={() => setChangingPage(-1)}
                  disabled={savingFeedbackPage == page_number}
                >
                  <Clear />
                </IconButton>
                <IconButton
                  color="success"
                  onClick={() => saveFeedback(pageData)}
                  disabled={savingFeedbackPage == page_number}
                >
                  {savingFeedbackPage == page_number ? (
                    <CircularProgress
                      style={{
                        color: "#3f51b5",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  ) : (
                    <Save />
                  )}
                </IconButton>
              </Flex>
            )}
          </Flex>
          {feedback && isHovered && (
            <div
              style={{
                background: "lightyellow",
                padding: "3px",
                marginTop: "10px",
                minWidth: "200px",
              }}
            >
              <Text customFontSize={0.8}>
                Suggestion to chage this classification to{" "}
                <i>{feedback.type_name}</i> is under review.
              </Text>
            </div>
          )}
        </div>
      </div>
    );
  };

  const saveFeedback = (data) => {
    setSavingFeedbackPage(data.page_index);
    createFeedback(packetData.doc_id, data.page_id, {
      text: "",
      type_name: selectedType,
    }).then((res) => {
      if (res) {
        setSavingFeedbackPage(-1);
        setChangingPage(-1);
        setSelectedType("placeholder");
        setFeedbackedPages(feedbackedPages.concat([data.page_index]));
        refetchFeedback();
      }
    });
  };

  const noClassificationOverlay = (page_number) => {
    if (page_number == currPage) return;
    return (
      <div
        style={{
          top: 0,
          width: "1%",
          margin: "20px",
        }}
        key={page_number}
      >
        <Flex
          column
          style={{
            background: "gainsboro",
            border: "2px solid gray",
            borderRadius: "10px",
            minHeight: "50px",
            paddingRight: "15px",
            paddingLeft: "15px",
            display: "table",
            width: "1%",
          }}
        >
          <Flex
            alignCenter
            style={{
              gap: "10px",
              height: "50px",
              whiteSpace: "nowrap",
            }}
          >
            <Text customFontSize={1}>Page {page_number}:</Text>
            <Text customFontSize={1}>
              <i>Unclassified</i>
            </Text>
          </Flex>
        </Flex>
      </div>
    );
  };

  if (!fileUrl) return <></>;

  return (
    <Flex style={{ height: "calc(100vh - 130px)" }}>
      <div style={{ width: "250px", paddingTop: "10px" }}>
        <Thumbnails />
      </div>
      <Container height="calc(100vh - 115px)" width="100%" overflow="hidden">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.js">
          <Viewer
            defaultScale={SpecialZoomLevel.PageWidth}
            fileUrl={fileUrl}
            plugins={[thumbnailPluginInstance, pageNavigationPluginInstance]}
            initialPage={initialPage - 1}
            onDocumentLoad={(e) => {
              setNumPages(e.doc.numPages);
              setIsDocumentLoaded(true);
            }}
            onPageChange={(e) => {
              setCurrentPage(e.currentPage);
            }}
            renderPage={renderPage}
          />
        </Worker>
      </Container>
    </Flex>
  );
};

export default DeafultLayoutPDFViewer;
