import {
  Button,
  CircularProgress,
  ListSubheader,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Flex from "styled-flex-component";
import {
  GetInsightsContext,
  GetInsightsContextType,
} from "../../../pages/GetInsights/context/getInsightsContext";
import { Text } from "../../UI/Typography";
import { getDocTypes } from "../../../api/classify.api";
import {
  createDocFeedback,
  getDocumentFeedback,
} from "../../../api/feedback.api";
import { isEmpty } from "lodash";
import { getDocument } from "../../../api/files.api";
import SearchTypeField from "../../UI/Common/SearchTypeField";

const DocumentFeedbackSelector = () => {
  const { packetData } = useContext(
    GetInsightsContext
  ) as GetInsightsContextType;

  const [selectedType, setSelectedType] = useState(
    packetData.type ?? "placeholder"
  );
  const [initialType, setInitialType] = useState("placeholder");
  const [options, setOptions] = useState<any>([]);
  const [allOptions, setAllOptions] = useState<any>([]);
  const [feedback, setFeedback] = useState<any>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [document, setDocument] = useState<any>();
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    setIsLoading(true);
    getDocument(packetData.doc_id).then((res) => {
      setDocument(res);
    });
    getDocTypes().then((res) => {
      setOptions(res.data);
      setAllOptions(res.data);
    });
    getDocumentFeedback(packetData.doc_id).then((res) => {
      setFeedback(res.filter((feedback) => feedback.status == null));
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (allOptions && document) {
      const currType = document.document_types.name ?? "placeholder";

      setSelectedType(currType);
      setInitialType(currType);
    }
  }, [allOptions, document]);

  useEffect(() => {
    const filteredTypeOptions = allOptions.filter((option) =>
      option.name.toLowerCase().includes(searchKey.toLowerCase())
    );
    setOptions(filteredTypeOptions);
  }, [searchKey]);

  const saveDocFeedback = () => {
    setIsSaving(true);
    createDocFeedback(packetData.doc_id, {
      text: "",
      type_name: selectedType,
    }).then((res) => {
      if (res) {
        setIsSaving(false);
        setFeedback(feedback.concat([res]));
      }
    });
  };

  return (
    <Flex
      style={{
        border: isEmpty(feedback) ? "1px solid white" : "2px dashed orange",
        borderRadius: "40px",
        paddingRight: isEmpty(feedback) ? "10px" : "0px",
        marginLeft: "10px",
        minWidth: "200px",
        height: "45px",
      }}
      alignCenter
      justifyCenter
    >
      {isLoading ? (
        <CircularProgress
          style={{
            color: "whitesmoke",
            width: "20px",
            height: "20px",
          }}
        />
      ) : !isEmpty(feedback) ? (
        <Tooltip
          title={`Suggestion to change the classification to 
              ${feedback[0]?.doc_type_name ?? selectedType} is under review.`}
        >
          <div
            style={{
              cursor: "default",
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              color="white"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {document?.document_types?.name}
            </Text>
          </div>
        </Tooltip>
      ) : (
        <Select
          onChange={(e) => {
            setSelectedType(e.target.value);
          }}
          value={selectedType}
          disableUnderline={true}
          sx={{
            color: "white",
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ".MuiSvgIcon-root ": {
              fill: "white !important",
            },
            height: "45px",
            paddingLeft: "10px",
            paddingBottom: "15px",
            justifyContent: "center",
            justifySelf: "center",
            paddingTop: "13px",
            paddingRight: "15px",
          }}
          onClose={() => setSearchKey("")}
        >
          <ListSubheader
            style={{
              paddingRight: "10px",
              paddingLeft: "10px",
              marginBottom: "10px",
            }}
          >
            <SearchTypeField
              searchKey={searchKey}
              setSearchKey={setSearchKey}
            />
          </ListSubheader>
          <MenuItem key={-1} value="placeholder" style={{ display: "none" }}>
            <Text color="white">Select item type</Text>
          </MenuItem>
          {!options.length && searchKey ? (
            <Flex justifyCenter alignCenter style={{ padding: "10px" }}>
              <Text color="gray"> No type has matched your search</Text>
            </Flex>
          ) : (
            options.map((option: any, index) => (
              <MenuItem
                key={index}
                value={option.name}
                style={{
                  display: option.name == selectedType ? "none" : "block",
                }}
              >
                {option.name}
              </MenuItem>
            ))
          )}
        </Select>
      )}

      {isEmpty(feedback) && initialType != selectedType && (
        <>
          {isSaving ? (
            <CircularProgress
              style={{
                color: "#3f51b5",
                width: "20px",
                height: "20px",
              }}
            />
          ) : (
            <Button
              onClick={() => {
                saveDocFeedback();
              }}
            >
              Suggest Change
            </Button>
          )}
        </>
      )}
    </Flex>
  );
};

export default DocumentFeedbackSelector;
