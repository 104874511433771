import { useContext } from "react";
import {
  InsightsReadOnlyContext,
  InsightsReadOnlyContextType,
} from "../../../pages/InsightsReadOnlyPage/context/insightsReadOnlyContext";
import { TextField } from "@mui/material";

interface ISearchClauseProps {
  isDisabled: boolean;
}
const SearchClause = ({ isDisabled }: ISearchClauseProps) => {
  const { searchKey, setSearchKey } = useContext(
    InsightsReadOnlyContext
  ) as InsightsReadOnlyContextType;
  return (
    <TextField
      placeholder="Search for an attribute"
      variant="outlined"
      value={searchKey}
      disabled={isDisabled}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKey(event.target.value);
      }}
      style={{ background: "white" }}
      fullWidth
    />
  );
};

export default SearchClause;
