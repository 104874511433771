import DocFeedbackRow from "./DocFeedbackRow";
import PageFeedbackRow from "./PageFeedbackRow";

interface Props {
  feedback: any;
  index: number;
  refetch: () => void;
}

export default function FeedbacksTableItem({
  feedback,
  index,
  // refetch,
}: Props) {
  return (
    <div key={index}>
      {feedback.page_id ? (
        <PageFeedbackRow feedback={feedback} />
      ) : (
        <DocFeedbackRow feedback={feedback} />
      )}
    </div>
  );
}
