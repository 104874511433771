import GetInsightsHeader from "../../components/GetInsights/Header";
import Flex from "styled-flex-component";
import Container from "../../components/UI/Common/Container";
import { useEffect, useState } from "react";
import GetInsightsProvider from "../GetInsights/context/getInsightsContext";
import PDFViewerPageIndicator from "../../components/GetInsights/PDFViewerPageIndicator";
import GetInsightsLayoutStyled from "../../components/GetInsights/Layout/Layout.styled";
import { Panel, PanelGroup } from "react-resizable-panels";
import DeafultLayoutPDFViewer from "../../components/GetInsights/DeafultLayoutPDFViewer";
import { useLocation, useNavigate } from "react-router-dom";
import { getDocTypes, getPageTypes } from "../../api/classify.api";
import { getDocument, getDocumentUrl } from "../../api/files.api";
import { getDocumentPageFeedbacks } from "../../api/feedback.api";

const ItemTypeClassificationPage = () => {
  window.addEventListener("keydown", function (event) {
    if ((event.ctrlKey || event.metaKey) && event.code == "ArrowDown")
      event.preventDefault();
    if ((event.ctrlKey || event.metaKey) && event.code == "ArrowUp")
      event.preventDefault();
  });
  const [pageTypes, setPageTypes] = useState([]);
  const [docTypes, setDocTypes] = useState([]);
  const location = useLocation();
  const { packetData } = location.state;
  const [pages, setPages] = useState();
  const [feedbacks, setFeedbacks] = useState();

  const [currPage, setCurrPage] = useState(packetData);
  const [assignedPages, setAssignedPages] = useState([]);
  const navigate = useNavigate();
  const [fileUrl, setFileUrl] = useState<any>("");

  useEffect(() => {
    getDocumentUrl(packetData.doc_id).then((res) => {
      setFileUrl(res);
    });
    getDocument(packetData.doc_id).then((res) => {
      setPages(res.document_pages);
    });
    if (packetData.parentDoc) {
      setAssignedPages(packetData.parentDoc.pages);
    }
    getPageTypes().then((res) => {
      setPageTypes(res);
    });
    if (!packetData.page_id) {
      getDocTypes().then((res) => {
        setDocTypes(res.data);
      });
    }
    fetchFeedback();
  }, []);

  const fetchFeedback = () => {
    getDocumentPageFeedbacks(packetData.doc_id).then((res) => {
      setFeedbacks(
        res.filter(
          (feedback) =>
            (feedback.doc_id = packetData.doc_id && feedback.status == null)
        )
      );
    });
  };

  const redirect = () => {
    getDocument(packetData.doc_id).then((res) => {
      setPages(res.document_pages);
    });
    if (assignedPages.length > 1) {
      const nextPage = packetData.parentDoc.pages.filter(
        (page) =>
          page.page_index > currPage.page_index && page.page_type == null
      )[0];

      if (nextPage) {
        setCurrPage({ ...nextPage, parentDoc: packetData.parentDoc });
      } else {
        navigate("/");
      }
    } else navigate("/");
  };

  return (
    <GetInsightsProvider
      fileUrl={fileUrl}
      pageTypeOptions={pageTypes}
      docTypeOptions={docTypes}
      packetData={currPage}
      pages={pages}
      feedbacks={feedbacks}
    >
      <GetInsightsLayoutStyled>
        <GetInsightsHeader onSaveSuccess={() => redirect()} />
        <Container topInnerSpacing={4}>
          <Flex justifyBetween full>
            <PanelGroup autoSaveId="sidebar" direction="horizontal">
              <Panel order={2}>
                <Container
                  topInnerSpacing={0}
                  bottomInnerSpacing={1.0}
                  leftInnerSpacing={0}
                  rightInnerSpacing={1.6875}
                >
                  <DeafultLayoutPDFViewer
                    initialPage={Number(currPage.page_index)}
                    refetchFeedback={() => fetchFeedback()}
                    fileUrl={fileUrl}
                  />
                  <PDFViewerPageIndicator />
                </Container>
              </Panel>
            </PanelGroup>
          </Flex>
        </Container>
      </GetInsightsLayoutStyled>
    </GetInsightsProvider>
  );
};

export default ItemTypeClassificationPage;
