import { ITag } from "../../../@types/Tag";
import DocumentTagChip from "../DocumentTagChip";

interface IDocumentTagsProps {
  docTags: ITag[];
  onClickTag: (tag: ITag) => void;
  onClickMore: () => void;
}

const DocumentTags = ({
  docTags,
  onClickTag,
  onClickMore,
}: IDocumentTagsProps) => {
  const getMoreCount = () => {
    return docTags.length - 2;
  };

  return (
    <span
      style={{
        height: "38px",
      }}
    >
      {docTags
        .filter((_v, index) => index < 2)
        .map((tag, index) => {
          return (
            <DocumentTagChip
              key={index}
              label={tag.name}
              title={tag.name.length > 10 ? tag.name : null}
              onClick={() => {
                onClickTag(tag);
              }}
              color={tag.color}
            />
          );
        })}
      {docTags.length > 2 && (
        <DocumentTagChip
          label={`+${getMoreCount()} more tag${getMoreCount() > 1 ? "s" : ""}`}
          title="View all tags"
          onClick={() => {
            onClickMore();
          }}
          color="gainsboro"
        />
      )}
    </span>
  );
};

export default DocumentTags;
