import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import Container from "../../UI/Common/Container";
import Flex from "styled-flex-component";
import { Text } from "../../UI/Typography";
import { useContext } from "react";
import {
  GetInsightsContext,
  GetInsightsContextType,
} from "../../../pages/GetInsights/context/getInsightsContext";
import { MeliorTranslate } from "../../MeliorTranslate";
import { Theme } from "../../../theme";

interface Props {
  items: any;
  hasNewBlankAnswer: boolean;
}

const TextMatchesNavigation = ({ items, hasNewBlankAnswer }: Props) => {
  const { textMatchIndex, setTextMatchIndex, setSelectedInsightId } =
    useContext(GetInsightsContext) as GetInsightsContextType;

  function goLeft(e) {
    e.stopPropagation();
    if (textMatchIndex === 0) return;

    setTextMatchIndex(textMatchIndex - 1);
    setSelectedInsightId(items[textMatchIndex - 1].insight_id);
  }

  function goRight(e) {
    e.stopPropagation();
    if (textMatchIndex === items.length - 1) return;

    setTextMatchIndex(textMatchIndex + 1);
    setSelectedInsightId(items[textMatchIndex + 1].insight_id);
  }
  return (
    <Container>
      <Flex justifyBetween alignCenter>
        <Container
          style={{ visibility: hasNewBlankAnswer ? "hidden" : "visible" }}
          cursor="pointer"
          onClick={goLeft}
        >
          <ChevronLeft fontSize="inherit" />
        </Container>
        <Text customFontSize={0.625}>
          {textMatchIndex + 1} <MeliorTranslate valueKey="of" /> {items.length}{" "}
          <MeliorTranslate valueKey="Matches" />{" "}
          {hasNewBlankAnswer && (
            <Text
              customFontSize={0.625}
              style={{ fontStyle: "italic" }}
              color={Theme.warning}
            >
              (draft)
            </Text>
          )}
        </Text>
        <Container
          style={{ visibility: hasNewBlankAnswer ? "hidden" : "visible" }}
          cursor="pointer"
          onClick={goRight}
        >
          <ChevronRight fontSize="inherit" />
        </Container>
      </Flex>
    </Container>
  );
};

export default TextMatchesNavigation;
