import React, { createContext, useState } from "react";
import { InsightType } from "../../../enums/InsightType";
import {
  IEditSelectedClause,
  ISelectedEntity,
  ISelectedRegion,
} from "../../../components/GetInsights/helpers/types";
export interface IChatSource {
  page: number;
}

export interface IChat {
  isFromMe: boolean;
  message: string;
  sources?: IChatSource[];
}

export type InsightsReadOnlyContextType = {
  pageTypeOptions: any;
  docTypeOptions: any;
  packetData: any;
  pages: any;
  feedbacks: any;
  loadingInsights: any;
  insights: any;
  isDocumentLoaded: boolean;
  selectedInsight: string | ISelectedEntity | undefined;
  selectedInsightId: string;
  selectedInsightType: InsightType;
  editSelectedClause: IEditSelectedClause | undefined;
  selectedRegion: ISelectedRegion | undefined;
  selectedSourcePageIndex: number;
  textMatchIndex: number;
  chats: IChat[];
  currentPage: number;
  numPages: number;
  fileUrl: string;
  currentMatchIndex: number;
  matchCase: boolean;
  matchWord: boolean;
  annotated: [];
  backToPageTrigger: number;
  currentInsight: any;
  hasNewBlankAnswer: boolean;
  searchKey: string;
  setSelectedInsight: (insight: string | ISelectedEntity | undefined) => void;
  setSelectedInsightId: (insightId: string) => void;
  setSelectedInsightType: (type: InsightType) => void;
  setEditSelectedClause: (clause: IEditSelectedClause | undefined) => void;
  setSelectedRegion: (region: ISelectedRegion | undefined) => void;
  setSelectedSourcePageIndex: (pageIndex: number) => void;
  setTextMatchIndex: (index: number) => void;
  setChats: (chats: IChat[]) => void;
  setCurrentPage: (page: number) => void;
  setNumPages: (pages: number) => void;
  setIsDocumentLoaded: (val: boolean) => void;
  setCurrentMatchIndex: (index: number) => void;
  setMatchCase: (matchCase: boolean) => void;
  setMatchWord: (matchWord: boolean) => void;
  setAnnotated: (annotated: []) => void;
  setBackToPageTrigger: (trigger: number) => void;
  setCurrentInsight: (insight: any) => void;
  setHasNewBlankAnswer: (hasNewBlankAnswer: boolean) => void;
  setSearchKey: (key: string) => void;
};

export const InsightsReadOnlyContext =
  createContext<InsightsReadOnlyContextType | null>(null);

interface InsightsReadOnlyProviderProps {
  children: React.ReactNode;
  fileUrl: string;
  pageTypeOptions?: any;
  docTypeOptions?: any;
  packetData?: any;
  pages?: any;
  feedbacks?: any;
  loadingInsights?: any;
  insights?: any;
}

const InsightsReadOnlyProvider = ({
  children,
  fileUrl,
  pageTypeOptions,
  docTypeOptions,
  packetData,
  pages,
  feedbacks,
  loadingInsights,
  insights,
}: InsightsReadOnlyProviderProps) => {
  const [selectedInsight, setSelectedInsight] = useState<
    string | ISelectedEntity | undefined
  >();
  const [selectedInsightType, setSelectedInsightType] = useState<InsightType>(
    InsightType.CLAUSE
  );
  const [editSelectedClause, setEditSelectedClause] = useState<
    IEditSelectedClause | undefined
  >();
  const [selectedRegion, setSelectedRegion] = useState<
    ISelectedRegion | undefined
  >(undefined);
  const [selectedSourcePageIndex, setSelectedSourcePageIndex] =
    useState<number>(0);
  const [chats, setChats] = useState<IChat[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [numPages, setNumPages] = useState<number>(0);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState<boolean>(false);
  const [textMatchIndex, setTextMatchIndex] = useState<number>(0);
  const [currentMatchIndex, setCurrentMatchIndex] = useState<number>(0);
  const [matchCase, setMatchCase] = useState(false);
  const [matchWord, setMatchWord] = useState(false);
  const [annotated, setAnnotated] = useState<any>([]);
  const [backToPageTrigger, setBackToPageTrigger] = useState<number>(0);
  const [selectedInsightId, setSelectedInsightId] = useState("");
  const [currentInsight, setCurrentInsight] = useState<any>();
  const [hasNewBlankAnswer, setHasNewBlankAnswer] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState("");

  return (
    <InsightsReadOnlyContext.Provider
      value={{
        fileUrl,
        pageTypeOptions,
        docTypeOptions,
        packetData,
        pages,
        feedbacks,
        insights,
        loadingInsights,
        isDocumentLoaded,
        selectedInsight,
        selectedInsightId,
        selectedInsightType,
        editSelectedClause,
        selectedRegion,
        selectedSourcePageIndex,
        textMatchIndex,
        chats,
        currentPage,
        numPages,
        currentMatchIndex,
        matchCase,
        matchWord,
        annotated,
        backToPageTrigger,
        currentInsight,
        hasNewBlankAnswer,
        searchKey,
        setSelectedInsight,
        setSelectedInsightId,
        setIsDocumentLoaded,
        setSelectedInsightType,
        setEditSelectedClause,
        setSelectedRegion,
        setSelectedSourcePageIndex,
        setTextMatchIndex,
        setChats,
        setCurrentPage,
        setNumPages,
        setCurrentMatchIndex,
        setMatchCase,
        setMatchWord,
        setAnnotated,
        setBackToPageTrigger,
        setCurrentInsight,
        setHasNewBlankAnswer,
        setSearchKey,
      }}
    >
      {children}
    </InsightsReadOnlyContext.Provider>
  );
};

export default InsightsReadOnlyProvider;
