import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { MeliorTranslate } from "../../../MeliorTranslate";

interface ConfirmDialogProps {
  title: string;
  message: string;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmDialog = ({
  title,
  message,
  isOpen,
  onCancel,
  onConfirm,
}: ConfirmDialogProps) => {
  return (
    <Dialog open={isOpen} onClose={onCancel}>
      <DialogTitle>
        <MeliorTranslate valueKey={title} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <MeliorTranslate valueKey={message} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          <MeliorTranslate valueKey="No" />
        </Button>
        <Button onClick={onConfirm} autoFocus>
          <MeliorTranslate valueKey="Yes" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
