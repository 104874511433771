import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { TableContainer, TableHead } from "@mui/material";
import { IDocument } from "../../../../@types/Document";
import DocumentsTableItem from "./Item";
import Flex from "styled-flex-component";

interface IDocumentsTableProps {
  documents: IDocument[];
  refetch: () => void;
  grab: (doc_id: string, page_index: string, page_id: string) => void;
  unassign: (assignment_id: string, parent_assignment_id: string) => void;
  isUnassigned: boolean;
  isAssignedToOthers: boolean;
}

const DocumentsTable = ({
  documents,
  grab,
  unassign,
  isUnassigned,
  isAssignedToOthers,
  refetch,
}: IDocumentsTableProps) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <Flex
            alignCenter
            style={{
              height: "60px",
              borderBottom: "1px solid whitesmoke",
              fontWeight: "bold",
              width: "100%",
            }}
          >
            {/* Packet ID */}
            <Flex
              justifyCenter
              style={{
                width: "20%",
              }}
            >
              Packet Name
            </Flex>
            {/* Packet Type */}
            <Flex
              justifyCenter
              style={{
                width: "14%",
                textAlign: "center",
              }}
            >
              Packet Type
            </Flex>
            {/* Date Uploaded */}
            <Flex
              justifyCenter
              style={{
                width: "10%",
                textAlign: "center",
              }}
            >
              Date Uploaded
            </Flex>
            {/* Status */}
            <Flex justifyCenter style={{ width: "10%" }}>
              Status
            </Flex>
            {/* Assignee */}
            {isAssignedToOthers && (
              <Flex justifyCenter style={{ width: "15%" }}>
                Assignee
              </Flex>
            )}
            {/* Tags */}
            <Flex justifyCenter style={{ width: "10%", minWidth: "210px" }}>
              Tags
            </Flex>
            {/* Actions */}
            <Flex
              justifyCenter
              style={{
                width: isAssignedToOthers ? "20%" : "35%",
              }}
            >
              Actions
            </Flex>
          </Flex>
        </TableHead>
        <TableBody>
          {documents.map((document: IDocument, index) => (
            <DocumentsTableItem
              key={index}
              document={document}
              index={index}
              grab={(doc_id, page_index, page_id) =>
                grab(doc_id, page_index, page_id)
              }
              unassign={(assignment_id, parent_assignment_id) =>
                unassign(assignment_id, parent_assignment_id)
              }
              isUnassigned={isUnassigned}
              isAssignedToOthers={isAssignedToOthers}
              refetch={() => refetch()}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentsTable;
