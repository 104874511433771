import { Text } from "../../UI/Typography";
import moment from "moment";
import Flex from "styled-flex-component";
import { useEffect, useState } from "react";
import ViewButton from "../ViewButton";
import GrabAllButton from "../GrabAllButton";
import UnassignAllButton from "../UnassignAllButton";
import SubmitAllButton from "../SubmitAllButton";
import AddTagModal from "../AddTagModal";
import DocumentTableItemTags from "../DocumentTableItemTags";
import { isEmpty } from "lodash";
import PacketType from "../TableColumn/PacketType";

interface Props {
  document: any;
  index: number;
  grab: (doc_id: string, page_index: string, page_id: string) => void;
  unassign: (assignment_id: string, parent_assignment_id: string) => void;
  isUnassigned: boolean;
  isAssignedToOthers: boolean;
  updateStatus: (assignment_id: string) => void;
  refetch: () => void;
}

export default function PageParentItem({
  document,
  grab,
  unassign,
  isUnassigned,
  isAssignedToOthers,
  updateStatus,
  refetch,
}: Props) {
  const [unassignedPages, setUnassignedPages] = useState([]);
  const [submittedPages, setSubmittedPages] = useState([]);
  const [isTagOpen, setIsTagOpen] = useState(false);

  useEffect(() => {
    if (!document.pages) return;

    const unassigned = document.pages.filter((page) => page.page_type == null);
    const submitted = document.pages.filter((page) => page.page_type != null);
    setUnassignedPages(unassigned);
    setSubmittedPages(submitted);
  }, [document.pages]);

  return (
    <Flex
      justifyBetween
      alignCenter
      style={{
        paddingTop: "5px",
        paddingBottom: "5px",
        width: "100%",
      }}
    >
      <Flex style={{ width: "100%" }} alignCenter>
        {/* Packet ID */}
        <Flex
          alignCenter
          style={{
            width: "20.3%",
            minWidth: "200px",
            display: "flex",
            gap: "10px",
          }}
        >
          <Text>{document.doc_name ?? document.doc_id}</Text>
          <Text
            style={{
              background: "lightgray",
              borderRadius: "20px",
              height: "fit-content",
              paddingTop: "4px",
              paddingBottom: "4px",
              paddingRight: "9px",
              paddingLeft: "9px",
              marginRight: "15px",
            }}
          >
            {document.pages.length}
          </Text>
        </Flex>
        {/* Packet Type */}
        <PacketType
          document={document}
          isUnassigned={isUnassigned}
          isPages={true}
        />
        {/* Date Uploaded */}
        <Flex
          style={{
            width: "10.3%",
            minWidth: "50px",
          }}
        >
          <Text>{moment(document.created_at).format("lll")} </Text>
        </Flex>
        {/* Status */}
        <div
          style={{
            width: "10%",
            minWidth: "50px",
            color:
              document.status != "pending" && !isUnassigned ? "green" : "black",
            textAlign: "center",
          }}
        >
          {isUnassigned && <Text>For Classification</Text>}
        </div>
        {/* Asignee - empty space */}
        {isAssignedToOthers && (
          <div style={{ width: "15.5%", minWidth: "50px" }}> </div>
        )}
        {/*  Tags */}
        <Flex
          justifyCenter
          style={{
            width: "10%",
            minWidth: "210px",
          }}
        >
          <DocumentTableItemTags
            document={document}
            docTags={document.tags}
            addTag={() => {
              setIsTagOpen(true);
            }}
          />
        </Flex>
        {/* Actions */}
        {!isAssignedToOthers ? (
          <Flex
            justifyEnd
            style={{
              width: "33.5%",
            }}
          >
            {isUnassigned ? (
              <GrabAllButton
                document={document}
                grabPage={(doc_id, page_index, page_id) =>
                  grab(doc_id, page_index, page_id)
                }
              />
            ) : (
              <Flex style={{ gap: "10px" }} alignCenter>
                {!isEmpty(unassignedPages) && (
                  <UnassignAllButton
                    document={document}
                    pages={unassignedPages}
                    unassignPage={unassign}
                  />
                )}
                {!isEmpty(submittedPages) && (
                  <SubmitAllButton
                    pages={submittedPages}
                    submitPage={updateStatus}
                  />
                )}
              </Flex>
            )}
          </Flex>
        ) : (
          <Flex justifyEnd style={{ width: "19%" }}>
            <ViewButton document={document} />
          </Flex>
        )}
      </Flex>

      <AddTagModal
        documentTags={document.tags}
        documentId={document.doc_id}
        isOpen={isTagOpen}
        setIsOpen={setIsTagOpen}
        refetch={refetch}
        mode={document.tags.length ? "updating" : "adding"}
      />
    </Flex>
  );
}
