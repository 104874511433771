import { TextField } from "@mui/material";

interface ISearchTypeProps {
  searchKey: string;
  setSearchKey: (key: string) => void;
}

const SearchTypeField = ({ searchKey, setSearchKey }: ISearchTypeProps) => {
  return (
    <TextField
      variant="outlined"
      placeholder="Search for item type"
      value={searchKey}
      autoComplete="off"
      onChange={(e) => setSearchKey(e.target.value)}
      onKeyDown={(e) => {
        // Prevents autoselecting item while typing (default Select behaviour)
        if (e.key !== "Escape") {
          e.stopPropagation();
        }
      }}
      fullWidth
    />
  );
};

export default SearchTypeField;
