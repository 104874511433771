import { Button, CircularProgress, MenuItem, Select } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Flex from "styled-flex-component";
import { Text } from "../../UI/Typography";
import {
  classifyPageFromFeedback,
  getPageTypes,
} from "../../../api/classify.api";
import {
  acceptPageFeedback,
  rejectPageFeedback,
} from "../../../api/feedback.api";
import {
  FeedbackReviewContext,
  FeedbackReviewContextType,
} from "../../../pages/FeedbackReview/context/feedbackReviewContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PageFeedbackReview = () => {
  const { feedback } = useContext(
    FeedbackReviewContext
  ) as FeedbackReviewContextType;
  const navigate = useNavigate();

  const [selectedType, setSelectedType] = useState(
    feedback.type ?? "placeholder"
  );
  const [options, setOptions] = useState<any>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isRejecting, setIsRejecting] = useState<boolean>(false);
  const [isAccepting, setIsAccepting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReclassifying, setIsReclassifying] = useState<boolean>(false);
  const [initialPageType, setInitialPageType] = useState<any>();

  useEffect(() => {
    setIsLoading(true);
    getPageTypes().then((res) => {
      setOptions(res);
      setIsLoading(false);
    });

    setInitialPageType(feedback.page.type_name);
  }, []);

  useEffect(() => {
    if (options) {
      const currType = feedback.document_type ?? "placeholder";
      setSelectedType(currType);
    }
  }, [options]);

  const acceptFeedback = () => {
    setIsAccepting(true);
    acceptPageFeedback(feedback.feedback_id).then((res) => {
      if (res) {
        toast.success("Successfully accepted feedback");
        setIsAccepting(false);
        navigate("/feedback");
      }
    });
  };
  const rejectFeedback = () => {
    setIsRejecting(true);
    rejectPageFeedback(feedback.feedback_id).then((res) => {
      if (res) {
        toast.success("Successfully rejected feedback");
        setIsRejecting(false);
        navigate("/feedback");
      }
    });
  };

  const saveClassification = async () => {
    setIsSaving(true);
    await classifyPageFromFeedback(
      feedback.doc_id,
      feedback.page_id,
      selectedType,
      true
    );
    await rejectPageFeedback(feedback.feedback_id);
    toast.success("Successfully rejected & reclassified page");
    navigate("/feedback");
    setIsSaving(false);
  };

  return (
    <Flex
      style={{
        border: "2px dotted gray",
        borderRadius: "40px",
        paddingRight: "0px",
        marginLeft: "10px",
        minWidth: "200px",
        height: "55px",
        background: "white",
      }}
      alignCenter
      justifyCenter
    >
      {isLoading ? (
        <CircularProgress
          style={{
            color: "gray",
            width: "20px",
            height: "20px",
          }}
        />
      ) : !isReclassifying ? (
        <div
          style={{
            cursor: "default",
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Flex column>
            <Text
              style={{
                whiteSpace: "nowrap",
                marginRight: "10px",
              }}
            >
              {feedback.type_name}
            </Text>
            <Text
              color="gray"
              style={{
                whiteSpace: "nowrap",
                marginRight: "10px",
              }}
              customFontSize={0.7}
            >
              Changing from {initialPageType}
            </Text>
          </Flex>
          {
            <Button
              variant="contained"
              color="success"
              onClick={() => acceptFeedback()}
            >
              {isAccepting ? (
                <CircularProgress
                  style={{
                    color: "whitesmoke",
                    width: "20px",
                    height: "20px",
                  }}
                />
              ) : (
                "Accept"
              )}
            </Button>
          }
          <Button
            variant="contained"
            color="error"
            onClick={() => rejectFeedback()}
          >
            {isRejecting ? (
              <CircularProgress
                style={{
                  color: "whitesmoke",
                  width: "20px",
                  height: "20px",
                }}
              />
            ) : (
              "Reject"
            )}
          </Button>
          <Button
            variant="contained"
            onClick={() => setIsReclassifying(true)}
            style={{
              whiteSpace: "nowrap",
            }}
          >
            Reject & Reclassify
          </Button>
        </div>
      ) : (
        <div
          style={{
            cursor: "default",
            display: "flex",
            gap: "10px",
            width: "100%",
            paddingLeft: "20px",
            paddingRight: "20px",
            justifyContent: "space-between",
            height: "40px",
          }}
        >
          <Select
            onChange={(e) => {
              setSelectedType(e.target.value);
            }}
            value={selectedType}
            disableUnderline={true}
            sx={{
              height: "40px",
              paddingBottom: "15px",
              justifyContent: "center",
              justifySelf: "center",
              paddingTop: "13px",
              paddingRight: "15px",
              width: "100%",
            }}
          >
            <MenuItem key={-1} value="placeholder" style={{ display: "none" }}>
              <Text>Select item type</Text>
            </MenuItem>
            {options
              .filter(
                (option) =>
                  option.name != initialPageType &&
                  option.name != feedback.type_name
              )
              .map((option: any, index) => (
                <MenuItem key={index} value={option.type_id}>
                  {option.code == "None"
                    ? option.name
                    : `${option.code} - ${option.name}`}
                </MenuItem>
              ))}
          </Select>
          <Button variant="contained" onClick={() => setIsReclassifying(false)}>
            Cancel
          </Button>
          {selectedType != "placeholder" && (
            <Button
              variant="contained"
              color="success"
              onClick={() => saveClassification()}
            >
              {isSaving ? (
                <CircularProgress
                  style={{
                    color: "whitesmoke",
                    width: "20px",
                    height: "20px",
                  }}
                />
              ) : (
                "Save"
              )}
            </Button>
          )}
        </div>
      )}
    </Flex>
  );
};

export default PageFeedbackReview;
