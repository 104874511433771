import Flex from "styled-flex-component";
import { Text } from "../../UI/Typography";
import Container from "../../UI/Common/Container";
import { isEmpty } from "lodash";
import { Theme } from "../../../theme";
import {
  Button,
  CircularProgress,
  IconButton,
  Input,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  GetInsightsContext,
  GetInsightsContextType,
} from "../../../pages/GetInsights/context/getInsightsContext";
import ClausesListItem from "./ClausesListItem";
import TextMatchesNavigation from "./TextMatchesNavigation";
import { MeliorTranslate } from "../../MeliorTranslate";
import {
  saveDocumentInsight,
  updateDocumentInsight,
} from "../../../api/annotate.api";
import { toast } from "react-toastify";
import { submit } from "../../../api/grab.api";
import DeleteButton from "./DeleteButton";
import { Edit } from "@mui/icons-material";
import AddDefinitionButton from "./AddDefinitionButton";
import NotFoundButton from "./NotFoundButton";
import SuggestedAnswer from "./SuggestedAnswer";

interface ICLausesListProps {
  refetchInsights: () => void;
}

const ClausesList = ({ refetchInsights }: ICLausesListProps) => {
  const {
    selectedInsight,
    selectedInsightId,
    editSelectedClause,
    selectedRegion,
    insights,
    packetData,
    loadingInsights,
    textMatchIndex,
    hasNewBlankAnswer,
    searchKey,
    isAddingNewRow,
    setSelectedRegion,
    setEditSelectedClause,
    setSelectedInsight,
    setSelectedInsightId,
    setTextMatchIndex,
    setCurrentInsight,
    setHasNewBlankAnswer,
    setIsAddingNewRow,
  } = useContext(GetInsightsContext) as GetInsightsContextType;
  const [hoveredInsight, setHoveredInsight] = useState<string>();
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [allClauses, setAllClauses] = useState<Array<any>>([]);
  const [displayedClauses, setDisplayedClauses] = useState<Array<any>>([]);
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const [internalDoneAnnotated, setInternalDoneAnnotated] = useState<any>([]);
  const [addingNewInsight, setAddingNewInsight] = useState<boolean>(false);
  const [newInsight, setNewInsight] = useState<any>({});
  const isEditing = (key) =>
    Boolean(editSelectedClause && editSelectedClause.clause !== key);

  useEffect(() => {
    const filteredBySearchKey = allClauses.filter((clause) =>
      clause.clause_name.toLowerCase().includes(searchKey.toLowerCase())
    );
    setDisplayedClauses(filteredBySearchKey);
  }, [searchKey]);

  useEffect(() => {
    if (insights) {
      const groupedInsights = groupInsights(insights);
      const clausesWithId = attachClauseId(groupedInsights, 0);
      setInternalDoneAnnotated(clausesWithId);
      setAllClauses(clausesWithId);
      setDisplayedClauses(clausesWithId);
    }
  }, [insights]);

  useEffect(() => {
    if (isAddingNewRow == true) addNewRow();
    else {
      setDisplayedClauses(
        displayedClauses.filter((clause) => clause.insight_id != "new")
      );
      reset();
    }
  }, [isAddingNewRow]);

  const groupInsights = (insights) => {
    let groupedInsights: any = [];
    insights.forEach((insight) => {
      const parentInsightIndex = groupedInsights.findIndex(
        (groupedInsight: any) =>
          groupedInsight.clause_name == insight.clause_name
      );

      if (parentInsightIndex != -1)
        groupedInsights[parentInsightIndex].answers =
          groupedInsights[parentInsightIndex].answers.concat(insight);
      else
        groupedInsights = groupedInsights.concat({
          clause_name: insight.clause_name,
          answers: [insight],
        });
    });

    groupedInsights.forEach((group) => {
      if (group.answers.length > 1) {
        group.answers = group.answers.sort(
          (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at)
        );
      }
    });

    const currInsight = groupedInsights.find(
      (insight) => insight.clause_name == selectedInsight
    );
    setCurrentInsight(currInsight);

    return groupedInsights;
  };

  const getBboxesValue = (): number[][] => {
    const arr: number[][] = [];
    if (!selectedRegion?.bboxes.length) {
      return [];
    }

    selectedRegion.bboxes.map((bbox) => {
      arr.push([bbox.left, bbox.top, bbox.width, bbox.height]);
    });

    return arr as number[][];
  };

  const attachClauseId = (clauses, startIndex) => {
    if (!clauses) return [];
    const clausesWithId: Array<any> = [];

    clauses.forEach((item, i) => {
      clausesWithId.push({
        id: (startIndex + i + 1).toString(),
        ...item,
      });
      return;
    });
    return clausesWithId;
  };

  async function confirm() {
    setIsConfirming(true);
    try {
      updateClauseValues(selectedRegion?.text ?? "", getBboxesValue(), false);
    } catch (e) {
      console.error(e);
      setIsConfirming(false);
    }
  }

  async function reset() {
    setSelectedInsight("");
    setSelectedInsight("");
    setIsResetting(false);
    setIsConfirming(false);
    setEditSelectedClause(undefined);
    setSelectedRegion(undefined);
    setHasNewBlankAnswer(false);
    setAddingNewInsight(false);
  }

  const updateClauseValues = (answer, bboxes, notFound) => {
    try {
      updateDocumentInsight(packetData.doc_id, selectedInsightId, {
        clause_name: selectedInsight,
        text: answer,
        bboxes: bboxes,
        page_index: notFound ? 0 : selectedRegion?.page ?? 0,
      }).then(() => {
        refetchInsights();
        submit(
          packetData.assignment_id,
          packetData.status == "classified"
            ? "classified_annotated"
            : "annotated"
        );

        setEditSelectedClause(undefined);
        setSelectedRegion(undefined);
        setIsResetting(false);
        setIsConfirming(false);
        setSelectedInsight("");
        setSelectedInsightId("");
        toast.success(
          selectedInsight +
            (!notFound ? " updated successfully." : " marked as not found")
        );
      });
    } catch (e: any) {
      toast.error(e?.detail ?? "Something went wrong");
      setIsConfirming(false);
    }
  };

  const notFound = () => {
    setIsConfirming(true);
    try {
      updateClauseValues("", [[0, 0, 0, 0]], true);
    } catch (e) {
      console.error(e);
      setIsConfirming(false);
    }
  };

  const editSelection = (e, clause) => {
    e.stopPropagation();
    setEditSelectedClause({
      clause: clause.clause_name,
      text: clause.text,
    });
    setSelectedRegion(undefined);
  };

  const addNewRow = () => {
    const empty = [
      {
        insight_id: "new",
        created_at: "",
        updated_at: "",
        clause_id: "",
        text: "",
        bboxes: [],
        document_id: "",
        user_id: null,
        clause_name: "",
      },
    ];
    setAddingNewInsight(true);
    setNewInsight(empty[0]);
    setDisplayedClauses(empty.concat(displayedClauses));
    setSelectedInsight("");
    setEditSelectedClause({
      clause: "",
      text: "",
    });
  };

  const createNew = () => {
    try {
      setIsConfirming(true);
      saveDocumentInsight(packetData.doc_id, {
        clause_name: newInsight.clause_name,
        text: selectedRegion?.text ?? "",
        bboxes: getBboxesValue(),
        page_index: selectedRegion?.page ?? 0,
      }).then((res) => {
        if (res) {
          const updatedlist = [res].concat(
            displayedClauses.filter((clause) => clause.insight_id != "new")
          );
          setDisplayedClauses(updatedlist);

          setEditSelectedClause(undefined);
          setSelectedRegion(undefined);
          setIsResetting(false);
          setIsConfirming(false);
          setSelectedInsight("temp_app");
          setSelectedInsightId("");
          setAddingNewInsight(false);
          setIsAddingNewRow(false);

          refetchInsights();
          submit(
            packetData.assignment_id,
            packetData.status == "classified"
              ? "classified_annotated"
              : "annotated"
          );
          toast.success("Successfully created a new insight");
          setIsConfirming(false);
        }
      });
    } catch (e: any) {
      toast.error(e?.detail ?? "Something went wrong");
      setIsConfirming(false);
    }
  };
  const createNewDefinition = (clause) => {
    try {
      setIsConfirming(true);
      saveDocumentInsight(packetData.doc_id, {
        clause_name: clause.clause_name,
        text: selectedRegion?.text ?? "",
        bboxes: getBboxesValue(),
        page_index: selectedRegion?.page ?? 0,
      }).then((res) => {
        if (res) {
          setEditSelectedClause(undefined);
          setSelectedRegion(undefined);
          setIsResetting(false);
          setSelectedInsightId("");

          refetchInsights();
          toast.success("Successfully added a new insight definition");
          setIsConfirming(false);
          setHasNewBlankAnswer(false);
        }
      });
    } catch (e: any) {
      toast.error(e?.detail ?? "Something went wrong");
      setIsConfirming(false);
    }
  };

  const addDefinition = (clause: any, index) => {
    const newAnswers = clause.answers.concat({
      insight_id: "blank_answer",
      text: "Highlight and select definition in the document",
      bboxes: [],
    });
    setHasNewBlankAnswer(true);
    const temp = displayedClauses;
    temp[index].answers = newAnswers;
    setDisplayedClauses(temp);
    setTextMatchIndex(newAnswers.length - 1);
    setEditSelectedClause({
      clause: clause.clause_name,
      text: clause.text,
    });
  };

  const getAnswerStatus = (answer) => {
    if (answer.text == "")
      return {
        status: "Not Found",
        displayText: answer.updated_by
          ? `Marked as not found by ${answer.updated_by.email}`
          : "",
      };
    if (answer.created_by && answer.updated_by) {
      //Same create and update - Added
      if (answer.created_by.email == answer.updated_by.email)
        return {
          status: "New",
          displayText: `Added by ${answer.created_by.email}`,
        };
      //Create by someone and updated by someone else - Edited
      else {
        return {
          status: "Edited",
          displayText: `Edited by ${answer.updated_by.email}`,
        };
      }
    } else {
      if (!answer.created_by) {
        //No create and have update - Edited
        if (answer.updated_by) {
          return {
            status: "Edited",
            displayText: `Edited by ${answer.updated_by.email}`,
          };
        }
        //No create and no update - none
        else {
          return {
            status: "Extracted",
            displayText: "",
          };
        }
      } else {
        //Have create and no update - Added
        return {
          status: "New",
          displayText: `Added by ${answer.created_by.email}`,
        };
      }
    }
  };

  return (
    <div
      style={{
        height: "100%",
        marginTop: "9px",
      }}
    >
      {/* List of attributes */}
      <div
        style={{
          overflowY: "scroll",
          height: "100%",
          background: "white",
        }}
      >
        {isEmpty(insights) && (
          <Flex
            justifyCenter
            style={{
              marginTop: "20px",
            }}
          >
            {!loadingInsights ? (
              !addingNewInsight && <>No extractions found.</>
            ) : (
              <CircularProgress
                style={{
                  color: "#3f51b5",
                  width: "30px",
                  height: "30px",
                }}
              />
            )}
          </Flex>
        )}
        {displayedClauses.map((clause, index) => (
          <Container key={clause.clause_name}>
            {selectedInsight !== clause.clause_name && (
              <div>
                <ClausesListItem
                  index={index}
                  clause={clause}
                  hoveredInsight={hoveredInsight as string}
                  setHoveredInsight={setHoveredInsight}
                  isEditing={isEditing}
                  isHideable={true}
                  editSelection={editSelection}
                  isAnnotated={internalDoneAnnotated.includes(
                    clause.clause_name
                  )}
                  getAnswerStatus={getAnswerStatus}
                />
              </div>
            )}
            {selectedInsight === clause.clause_name && (
              <Container
                borderLeft={`4px solid ${Theme.primary}`}
                borderBottom="1px solid rgba(13, 24, 50, 0.12)"
                leftInnerSpacing={2}
                topInnerSpacing={2}
                bottomInnerSpacing={1.5}
                rightInnerSpacing={1.625}
                onClick={() => {
                  setTextMatchIndex(0);
                  setSelectedInsight("");
                }}
                backgroundColor="white"
              >
                {clause.insight_id != "new" ? (
                  <>
                    <Text customFontWeight={600} color={Theme.primaryDark}>
                      {clause.clause_name}
                    </Text>

                    <Container
                      topOuterSpacing={1.375}
                      backgroundColor={Theme.background}
                      leftInnerSpacing={0.75}
                      topInnerSpacing={1}
                      bottomInnerSpacing={1}
                      rightInnerSpacing={0.75}
                      wordBreak
                    >
                      {/* empty */}
                      {isEmpty(clause.answers[textMatchIndex].text) &&
                        isEmpty(
                          clause.answers[textMatchIndex].suggested_answer
                        ) &&
                        editSelectedClause?.clause != clause.clause_name && (
                          <i style={{ opacity: 0.4 }}>empty</i>
                        )}
                      {/* text */}
                      {!isEmpty(clause.clause_name) && (
                        <Flex column justifyBetween>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              minHeight: "6.09375rem",
                              gap: "10px",
                            }}
                          >
                            {/* Answer */}
                            <Text
                              customFontSize={0.8125}
                              customFontWeight={400}
                              style={{
                                fontStyle:
                                  clause.answers[textMatchIndex]?.insight_id ==
                                  "blank_answer"
                                    ? "italic"
                                    : "normal",
                              }}
                              color={
                                clause.answers[textMatchIndex]?.insight_id ==
                                "blank_answer"
                                  ? "gray"
                                  : "black"
                              }
                            >
                              {selectedRegion
                                ? selectedRegion.text
                                : clause.answers[textMatchIndex]?.text}
                            </Text>
                            {/* Suggested answer */}
                            {clause.answers[textMatchIndex].suggested_answer &&
                              editSelectedClause?.clause !=
                                clause.clause_name && (
                                <SuggestedAnswer clause={clause} />
                              )}
                            {/* Status */}
                            {!hasNewBlankAnswer && (
                              <Flex justifyBetween alignCenter>
                                <Text customFontSize={0.65} color="gray">
                                  {
                                    getAnswerStatus(
                                      clause.answers[textMatchIndex]
                                    ).displayText
                                  }
                                </Text>
                                {editSelectedClause?.clause !=
                                  clause.clause_name && (
                                  <Flex>
                                    <Tooltip title="Edit definition">
                                      <IconButton
                                        onClick={(e) =>
                                          editSelection(e, clause)
                                        }
                                      >
                                        <Edit />
                                      </IconButton>
                                    </Tooltip>
                                    <DeleteButton
                                      refetchInsights={refetchInsights}
                                    />
                                  </Flex>
                                )}
                              </Flex>
                            )}
                          </div>
                          {clause.answers.length > 1 && (
                            <TextMatchesNavigation
                              items={clause.answers}
                              hasNewBlankAnswer={hasNewBlankAnswer}
                            />
                          )}
                        </Flex>
                      )}
                    </Container>

                    {!Boolean(editSelectedClause) && (
                      <Container topOuterSpacing={1} textAlign="right">
                        <Flex full justifyEnd style={{ gap: "10px" }}>
                          <AddDefinitionButton
                            addDefinition={() => addDefinition(clause, index)}
                          />
                          {!(clause.answers[0].text == "") &&
                            clause.answers.length == 1 && (
                              <NotFoundButton notFound={notFound} />
                            )}
                        </Flex>
                      </Container>
                    )}

                    {Boolean(editSelectedClause) && (
                      <Container topOuterSpacing={1} textAlign="right">
                        <Flex full justifyEnd>
                          <Button
                            color="primary"
                            sx={{ marginRight: 1 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              reset();
                              refetchInsights();
                            }}
                            disabled={isResetting}
                          >
                            <MeliorTranslate valueKey="Cancel" />
                          </Button>
                          <Button
                            variant="outlined"
                            color="success"
                            disabled={isConfirming || !selectedRegion}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                clause.answers[textMatchIndex].insight_id ==
                                "blank_answer"
                              )
                                createNewDefinition(clause);
                              else confirm();
                            }}
                          >
                            {isConfirming ? (
                              <CircularProgress
                                style={{
                                  color: "#3f51b5",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            ) : (
                              <MeliorTranslate
                                valueKey={
                                  clause.answers[textMatchIndex].insight_id ==
                                  "blank_answer"
                                    ? "Save"
                                    : "Confirm"
                                }
                              />
                            )}
                          </Button>
                        </Flex>
                      </Container>
                    )}
                  </>
                ) : (
                  <>
                    {/* Input name */}
                    <Flex alignCenter justifyBetween>
                      <Input
                        style={{ fontSize: "16px" }}
                        disableUnderline={true}
                        placeholder="Enter an alias"
                        type="text"
                        value={newInsight.clause_name}
                        onChange={(e) => {
                          const updatedNew = {
                            ...newInsight,
                            clause_name: e.target.value,
                          };
                          setNewInsight(updatedNew);
                        }}
                      />
                    </Flex>
                    {/* Display answer */}
                    <Container
                      topOuterSpacing={1.375}
                      backgroundColor={Theme.background}
                      leftInnerSpacing={0.75}
                      topInnerSpacing={1}
                      bottomInnerSpacing={1}
                      rightInnerSpacing={0.75}
                      minHeight={6.09375}
                      wordBreak
                    >
                      {
                        <Flex
                          column
                          justifyBetween
                          style={{ minHeight: "6.09375rem" }}
                        >
                          {
                            <Text
                              customFontSize={0.8125}
                              customFontWeight={400}
                              color={selectedRegion ? "black" : "gray"}
                            >
                              {selectedRegion
                                ? selectedRegion.text
                                : "Select text within the document"}
                            </Text>
                          }
                        </Flex>
                      }
                    </Container>
                    {/* Discard & Save */}
                    {
                      <Container topOuterSpacing={1} textAlign="right">
                        <Flex full justifyEnd>
                          <Button
                            color="primary"
                            sx={{ marginRight: 1 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsAddingNewRow(false);
                            }}
                            disabled={isResetting}
                          >
                            <MeliorTranslate valueKey="Discard" />
                          </Button>
                          <Button
                            variant="outlined"
                            color="success"
                            disabled={
                              isConfirming ||
                              !selectedRegion ||
                              !newInsight.clause_name
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              createNew();
                            }}
                          >
                            {isConfirming ? (
                              <CircularProgress
                                style={{
                                  color: "#3f51b5",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            ) : (
                              <MeliorTranslate valueKey="Save" />
                            )}
                          </Button>
                        </Flex>
                      </Container>
                    }
                  </>
                )}
              </Container>
            )}
          </Container>
        ))}
      </div>
    </div>
  );
};

export default ClausesList;
