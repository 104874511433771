import CardContainer from "../../components/UI/Common/Card/Card";
import Container from "../../components/UI/Common/Container";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Tab,
  Tabs,
} from "@mui/material";
import Flex from "styled-flex-component";
import { MeliorTranslate } from "../../components/MeliorTranslate";
import { MeliorTable } from "../../components/MeliorTable";
import { useContext, useEffect, useState } from "react";
import {
  exportAnnotations,
  exportClassifications,
  fetchDocumentsForExport,
  fetchPagesForExport,
} from "../../api/export.api";
import ExportTable from "../../components/Export/Tables/Table";
import FileSaver from "file-saver";
import { toast } from "react-toastify";
import { FilterContext, FilterContextType } from "../../context/FilterContext";

const ExportPage = () => {
  const { showTestData } = useContext(FilterContext) as FilterContextType;
  const [pageGroups, setPageGroups] = useState<any[]>([]);
  const [documents, setDocuments] = useState<any>();
  const [isLoadingPages, setIsLoadingPages] = useState(false);
  const [isLoadingDocs, setIsLoadingDocs] = useState(false);
  const [exportIds, setExportIds] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [listType, setListType] = useState("classifications");
  const [currList, setCurrList] = useState<any[]>([]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setExportIds([]);
    setSelectAll(false);

    const list = listType == "classifications" ? pageGroups : documents?.items;
    const filteredList = getToggledList(list);
    setCurrList(filteredList);
  }, [listType, pageGroups, documents, showTestData]);

  const getToggledList = (list) => {
    if (!list) return [];

    const temp: any = list.concat([]);

    const filterProperty = listType == "classifications" ? "doc_name" : "name";
    const filteredList = showTestData
      ? temp
      : temp.filter(
          (data) => !data[filterProperty].toLowerCase().includes("test")
        );

    return filteredList;
  };

  const refetch = () => {
    getPagesForExport();
    getDocumentsForExport();
  };

  const getPagesForExport = () => {
    setIsLoadingPages(true);
    fetchPagesForExport().then((res) => {
      setPageGroups(res);
      setIsLoadingPages(false);
    });
  };

  const getDocumentsForExport = () => {
    setIsLoadingDocs(true);
    fetchDocumentsForExport().then((res) => {
      setDocuments(res);
      setIsLoadingDocs(false);
    });
  };

  const doExport = () => {
    setIsSaving(true);

    if (listType == "classifications")
      exportClassifications(exportIds).then((res) => {
        if (res) {
          toast.success("Successfully exported classifications");
          FileSaver.saveAs(res);
          setExportIds([]);
          setSelectAll(false);
          setIsSaving(false);
        }
      });
    else
      exportAnnotations(exportIds).then((res) => {
        if (res) {
          toast.success("Successfully exported annotations");
          FileSaver.saveAs(res);
          setExportIds([]);
          setSelectAll(false);
          setIsSaving(false);
        }
      });
  };

  const handleSelectChange = () => {
    const checked = !selectAll;
    const items = currList;
    setExportIds(checked ? items.map((item) => item.doc_id) : []);
    setSelectAll(checked);
  };

  return (
    <Container outerSpacing={1}>
      <CardContainer>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Flex justifyBetween alignCenter>
            <Tabs value={0} style={{ overflow: "visible" }}>
              <Checkbox
                checked={
                  exportIds.length != 0 && exportIds.length == currList.length
                }
                indeterminate={
                  exportIds.length != 0 && exportIds.length != currList.length
                }
                onClick={() => handleSelectChange()}
              />
              <Tab
                label={
                  <Flex alignCenter>
                    <span>
                      <MeliorTranslate valueKey="Packet" />
                    </span>
                    <Container leftOuterSpacing={1} rightOuterSpacing={2}>
                      <Badge badgeContent={currList.length} color="primary" />
                    </Container>
                  </Flex>
                }
              />
            </Tabs>
            <Flex alignCenter style={{ gap: "20px", marginBottom: "10px" }}>
              <div
                style={{
                  background: "whitesmoke",
                  borderRadius: "5px",
                  border: "1px solid #3874CB",
                }}
              >
                <Button
                  variant={listType == "classifications" ? "contained" : "text"}
                  onClick={() => setListType("classifications")}
                >
                  Classifications
                </Button>
                <Button
                  variant={listType == "annotations" ? "contained" : "text"}
                  onClick={() => setListType("annotations")}
                >
                  Annotations
                </Button>
              </div>
              <Badge
                badgeContent={isSaving ? 0 : exportIds.length}
                color="warning"
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    doExport();
                  }}
                  disabled={!exportIds.length || isSaving}
                  style={{ width: "140px" }}
                >
                  {isSaving ? (
                    <CircularProgress
                      style={{
                        color: "#3f51b5",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  ) : (
                    "Export"
                  )}
                </Button>
              </Badge>
            </Flex>
          </Flex>
        </Box>

        <MeliorTable
          showLoading={Boolean(
            listType == "classifications" ? isLoadingPages : isLoadingDocs
          )}
          error={""}
          items={currList}
          tableEl={
            <ExportTable
              items={currList}
              itemType={listType}
              exportIds={exportIds}
              setExportIds={(ids) => setExportIds(ids)}
            />
          }
          loadingMessage={"Loading " + listType}
          emptyMessage={`No ${listType} to export`}
          errorMessage="An error has occurred"
        />
      </CardContainer>
    </Container>
  );
};

export default ExportPage;
