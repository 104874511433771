import { isEmpty } from "lodash";
import DocItem from "../DocItem";
import PageItem from "../PageItem";
import { useState } from "react";
import { submit } from "../../../../api/grab.api";
import { toast } from "react-toastify";

interface Props {
  document: any;
  index: number;
  grab: (doc_id: string, page_index: string, page_id: string) => void;
  unassign: (assignment_id: string, parent_assignment_id: string) => void;
  isUnassigned: boolean;
  isAssignedToOthers: boolean;
  refetch: () => void;
}

export default function DocumentsTableItem({
  document,
  index,
  grab,
  unassign,
  isUnassigned,
  isAssignedToOthers,
  refetch,
}: Props) {
  const [savingId, setSavingId] = useState("");
  async function updateStatus(assignment_id) {
    try {
      setSavingId(assignment_id);
      await submit(assignment_id);
      toast.success(`Task ${assignment_id} submitted successfully`);
      refetch();
      setSavingId("");
    } catch (e: any) {
      toast.error(e?.detail ?? "Something went wrong");
      setSavingId("");
    }
  }
  return (
    <div key={index}>
      {isEmpty(document.pages) ||
      (document.pages[0].page_id == null && isEmpty(document.pages)) ? (
        <DocItem
          document={document}
          grab={grab}
          isUnassigned={isUnassigned}
          isAssignedToOthers={isAssignedToOthers}
          unassign={unassign}
          savingId={savingId}
          setSavingId={setSavingId}
          refetch={refetch}
          updateStatus={updateStatus}
        />
      ) : (
        <PageItem
          document={document}
          index={index}
          grab={grab}
          unassign={unassign}
          isUnassigned={isUnassigned}
          isAssignedToOthers={isAssignedToOthers}
          refetch={refetch}
          updateStatus={updateStatus}
        />
      )}
    </div>
  );
}
