import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface IClassifyButtonProps {
  document: any;
  isShow: boolean;
  started: boolean;
}

const ClassifyButton = ({
  document,
  isShow,
  started,
}: IClassifyButtonProps) => {
  const navigate = useNavigate();

  if (!isShow) return;
  return (
    <Button
      variant="outlined"
      color="info"
      style={{ width: "135px" }}
      onClick={() => {
        navigate(`type/${document.doc_id}`, {
          state: {
            packetData: {
              ...document,
              page_index: 0,
              page_id: null,
            },
          },
        });
      }}
    >
      {started ? "Reclassify" : "Classify"}
    </Button>
  );
};
export default ClassifyButton;
