import React, { createContext, useState } from "react";
import { ITag } from "../@types/Tag";

export type FilterContextType = {
  currTag: ITag | null;
  showTestData: boolean;
  allTags: ITag[];
  setCurrTag: (currTag: ITag | null) => void;
  setshowTestData: (showTestData: boolean) => void;
  setAllTags: (tags: ITag[]) => void;
};

export const FilterContext = createContext<FilterContextType | null>(null);

interface FilterProviderProps {
  children: React.ReactNode;
}

const FilterProvider = ({ children }: FilterProviderProps) => {
  const [currTag, setCurrTag] = useState<ITag | null>(null);
  const [showTestData, setshowTestData] = useState<boolean>(false);
  const [allTags, setAllTags] = useState<ITag[]>([]);

  return (
    <FilterContext.Provider
      value={{
        currTag,
        showTestData,
        allTags,
        setCurrTag,
        setshowTestData,
        setAllTags,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
