import FilterProvider from "../../../context/FilterContext";
import Navbar from "../Navbar";

interface Props {
  children: React.ReactNode;
}

export default function DefaultLayout({ children }: Props) {
  return (
    <FilterProvider>
      <Navbar />
      <div style={{ paddingTop: "75px", width: "100%" }}>{children}</div>
    </FilterProvider>
  );
}
