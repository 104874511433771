import { Button, CircularProgress, MenuItem, Select } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Flex from "styled-flex-component";
import { Text } from "../../UI/Typography";
import { classifyDoc, getDocTypes } from "../../../api/classify.api";
import {
  acceptDocFeedback,
  rejectDocFeedback,
} from "../../../api/feedback.api";
import {
  FeedbackReviewContext,
  FeedbackReviewContextType,
} from "../../../pages/FeedbackReview/context/feedbackReviewContext";
import { getDocument } from "../../../api/files.api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const DocumentFeedbackSelector = () => {
  const { feedback } = useContext(
    FeedbackReviewContext
  ) as FeedbackReviewContextType;
  const navigate = useNavigate();

  const [selectedType, setSelectedType] = useState(
    feedback.type ?? "placeholder"
  );
  const [options, setOptions] = useState<any>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isRejecting, setIsRejecting] = useState<boolean>(false);
  const [isAccepting, setIsAccepting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReclassifying, setIsReclassifying] = useState<boolean>(false);
  const [initialDocumentType, setInitialDocumentType] = useState<any>();

  useEffect(() => {
    setIsLoading(true);
    getDocTypes().then((res) => {
      setOptions(res.data);
    });
    getDocument(feedback.doc_id).then((res) => {
      setInitialDocumentType(res.document_types.name);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (options) {
      const currType = feedback.document_type ?? "placeholder";
      setSelectedType(currType);
    }
  }, [options]);

  const acceptFeedback = () => {
    setIsAccepting(true);
    acceptDocFeedback(feedback.feedback_id).then((res) => {
      if (res) {
        toast.success("Successfully accepted feedback");
        setIsAccepting(false);
        navigate("/feedback");
      }
    });
  };
  const rejectFeedback = () => {
    setIsRejecting(true);
    rejectDocFeedback(feedback.feedback_id).then((res) => {
      if (res) {
        toast.success("Successfully rejected feedback");
        setIsRejecting(false);
        navigate("/feedback");
      }
    });
  };

  const saveClassification = async () => {
    setIsSaving(true);
    try {
      await classifyDoc(feedback.doc_id, selectedType, selectedType != null);
    } catch (e) {
      console.log(e);
    }
    await rejectDocFeedback(feedback.feedback_id);
    toast.success("Successfully rejected & reclassified document");
    navigate("/feedback");
    setIsSaving(false);
  };

  return (
    <Flex
      style={{
        border: "2px dotted gray",
        borderRadius: "40px",
        paddingRight: "0px",
        marginLeft: "10px",
        minWidth: "200px",
        height: "45px",
      }}
      alignCenter
      justifyCenter
    >
      {isLoading ? (
        <CircularProgress
          style={{
            color: "whitesmoke",
            width: "20px",
            height: "20px",
          }}
        />
      ) : !isReclassifying ? (
        <div
          style={{
            cursor: "default",
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Flex column>
            <Text
              color="white"
              style={{
                whiteSpace: "nowrap",
                marginRight: "10px",
              }}
            >
              {feedback.doc_type_name}
            </Text>
            <Text
              color="gray"
              style={{
                whiteSpace: "nowrap",
                marginRight: "10px",
              }}
              customFontSize={0.7}
            >
              Changing from {initialDocumentType}
            </Text>
          </Flex>
          {
            <Button
              variant="contained"
              color="success"
              onClick={() => acceptFeedback()}
            >
              {isAccepting ? (
                <CircularProgress
                  style={{
                    color: "whitesmoke",
                    width: "20px",
                    height: "20px",
                  }}
                />
              ) : (
                "Accept"
              )}
            </Button>
          }
          <Button
            variant="contained"
            color="error"
            onClick={() => rejectFeedback()}
          >
            {isRejecting ? (
              <CircularProgress
                style={{
                  color: "whitesmoke",
                  width: "20px",
                  height: "20px",
                }}
              />
            ) : (
              "Reject"
            )}
          </Button>
          <Button variant="contained" onClick={() => setIsReclassifying(true)}>
            Reject & Reclassify
          </Button>
        </div>
      ) : (
        <div
          style={{
            cursor: "default",
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Select
            onChange={(e) => {
              setSelectedType(e.target.value);
            }}
            value={selectedType}
            disableUnderline={true}
            sx={{
              color: "white",
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              ".MuiSvgIcon-root ": {
                fill: "white !important",
              },
              height: "45px",
              paddingBottom: "15px",
              justifyContent: "center",
              justifySelf: "center",
              paddingTop: "13px",
              paddingRight: "15px",
            }}
          >
            <MenuItem key={-1} value="placeholder" style={{ display: "none" }}>
              <Text color="white">Select item type</Text>
            </MenuItem>
            {options
              .filter(
                (option) =>
                  option.name != initialDocumentType &&
                  option.name != feedback.doc_type_name
              )
              .map((option: any, index) => (
                <MenuItem key={index} value={option.type_id}>
                  {option.name}
                </MenuItem>
              ))}
          </Select>
          <Button variant="contained" onClick={() => setIsReclassifying(false)}>
            Cancel
          </Button>
          {selectedType != "placeholder" && (
            <Button
              variant="contained"
              color="success"
              onClick={() => saveClassification()}
            >
              {isSaving ? (
                <CircularProgress
                  style={{
                    color: "whitesmoke",
                    width: "20px",
                    height: "20px",
                  }}
                />
              ) : (
                "Save"
              )}
            </Button>
          )}
        </div>
      )}
    </Flex>
  );
};

export default DocumentFeedbackSelector;
