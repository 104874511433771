import GetInsightsSidebarStyled from "./Sidebar.styled";
import ClausesList from "./ClausesList";

interface IInsightsSidebarProps {
  refetchInsights: () => void;
}
const InsightsSidebar = ({ refetchInsights }: IInsightsSidebarProps) => {
  return (
    <GetInsightsSidebarStyled>
      <ClausesList refetchInsights={() => refetchInsights()} />
    </GetInsightsSidebarStyled>
  );
};

export default InsightsSidebar;
