import { Button, IconButton } from "@mui/material";
import { IDocument } from "../../../@types/Document";
import { Edit } from "@mui/icons-material";
import DocumentTags from "../DocumentTags";
import Flex from "styled-flex-component";
import { MeliorTranslate } from "../../MeliorTranslate";
import { useContext, useState } from "react";
import { Text } from "../../UI/Typography";
import { ITag } from "../../../@types/Tag";
import {
  FilterContext,
  FilterContextType,
} from "../../../context/FilterContext";

interface IDocumentTableItemTagsProps {
  document: IDocument;
  docTags: ITag[];
  addTag: () => void;
}

const DocumentTableItemTags = ({
  document,
  docTags,
  addTag,
}: IDocumentTableItemTagsProps) => {
  const { setCurrTag } = useContext(FilterContext) as FilterContextType;
  const [hoveredDoc, setHoveredDoc] = useState<string>("");
  return (
    <Flex
      alignCenter
      style={{
        height: "60px",
      }}
    >
      {docTags?.length ? (
        <div
          style={{
            minWidth: "190px",
          }}
          onMouseEnter={() => setHoveredDoc(document.id)}
          onMouseLeave={() => setHoveredDoc("")}
        >
          {Boolean(hoveredDoc == document.id) && (
            <IconButton
              style={{
                height: "24px",
                width: "24px",
                marginRight: "5px",
                cursor: "pointer",
              }}
              onClick={() => addTag()}
            >
              <Edit fontSize="small" color="primary" />
            </IconButton>
          )}
          <DocumentTags
            docTags={docTags}
            onClickTag={(tag) => {
              setCurrTag(tag);
            }}
            onClickMore={() => addTag()}
          />
        </div>
      ) : (
        <Flex alignCenter justifyBetween>
          <Button
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              addTag();
            }}
          >
            <Text>
              <MeliorTranslate valueKey="Add Tags" />
            </Text>
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default DocumentTableItemTags;
