import { Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import Flex from "styled-flex-component";
import { Text } from "../../UI/Typography";

interface IGrabAllButtonProps {
  grabPage: (doc_id, page_index, page_id) => void;
  document: any;
}

const GrabAllButton = ({ document, grabPage }: IGrabAllButtonProps) => {
  const [grabCount, setGrabCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [isGrabbing, setIsGrabbing] = useState<boolean>(false);

  useEffect(() => {
    setPageCount(document.pages.length);
  }, []);

  const grabAll = () => {
    setIsGrabbing(true);
    document.pages.forEach((page) => {
      grabPage(document.doc_id, page.page_index, page.page_id);
      setGrabCount(grabCount + 1);
    });
  };
  return (
    <Flex>
      {isGrabbing ? (
        <Flex>
          <CircularProgress
            style={{
              color: "gray",
              width: "20px",
              height: "20px",
              marginRight: "10px",
            }}
          />
          <Text color="gray">
            {`Grabbing... ${pageCount - document.pages.length} of ${pageCount}`}
          </Text>
        </Flex>
      ) : (
        <Button onClick={() => grabAll()}>Grab All</Button>
      )}
    </Flex>
  );
};
export default GrabAllButton;
