import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface IViewButtonProps {
  document: any;
}

const ViewButton = ({ document }: IViewButtonProps) => {
  const navigate = useNavigate();
  return (
    <Button
      variant="outlined"
      style={{
        width: "150px",
        marginRight: document?.pages?.length ? "15px" : "40px",
      }}
      onClick={() => {
        navigate(`/insights-readonly/${document.doc_id}`);
      }}
    >
      View
    </Button>
  );
};
export default ViewButton;
