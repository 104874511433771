import { useContext } from "react";
import Container from "../UI/Common/Container";
import { Text } from "../UI/Typography";
import {
  InsightsReadOnlyContext,
  InsightsReadOnlyContextType,
} from "../../pages/InsightsReadOnlyPage/context/insightsReadOnlyContext";
import { MeliorTranslate } from "../MeliorTranslate";

const PDFViewerPageIndicator = () => {
  const { currentPage, numPages, isDocumentLoaded } = useContext(
    InsightsReadOnlyContext
  ) as InsightsReadOnlyContextType;
  if (!isDocumentLoaded) return <></>;
  return (
    <Container fullWidth textAlign="center" topOuterSpacing={1.8}>
      <Text>
        <MeliorTranslate valueKey="Page" /> {currentPage + 1}{" "}
        <MeliorTranslate valueKey="of" /> {numPages}
      </Text>
    </Container>
  );
};

export default PDFViewerPageIndicator;
