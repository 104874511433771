import { supabase } from "./supabase";

const USER_DATA_KEY = "supabaseUser";
export function saveUserSessionData(user: any) {
  localStorage.setItem(USER_DATA_KEY, JSON.stringify(user));
  return user;
}

export function getUserSessionData(): any | null {
  const user = localStorage.getItem(USER_DATA_KEY);
  return user ? JSON.parse(user) : null;
}

export function removeUserSessionData() {
  localStorage.removeItem(USER_DATA_KEY);
}

export async function getUserToken() {
  const { data } = await supabase.auth.getSession();
  return data.session?.access_token;
}
