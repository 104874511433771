import {
  RenderPage,
  RenderPageProps,
  SpecialZoomLevel,
  Viewer,
  Worker,
} from "@react-pdf-viewer/core";
import { useContext, useEffect, useState } from "react";
import Container from "../../UI/Common/Container";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  // RenderThumbnailItemProps,
  thumbnailPlugin,
} from "@react-pdf-viewer/thumbnail";
import Flex from "styled-flex-component";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import {
  FeedbackReviewContext,
  FeedbackReviewContextType,
} from "../../../pages/FeedbackReview/context/feedbackReviewContext";
import PageFeedbackReview from "../PageFeedbackReview";
interface ViewerProps {
  fileUrl: string;
}

const FeedbackReviewPDFViewer = ({ fileUrl }: ViewerProps) => {
  const { feedback, setCurrentPage, setIsDocumentLoaded } = useContext(
    FeedbackReviewContext
  ) as FeedbackReviewContextType;
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    if (feedback.page) {
      setCurrPage(feedback.page.page_index);
      setCurrentPage(feedback.page.page_index);
    }
  }, [feedback]);

  const renderPage: RenderPage = (props: RenderPageProps) => (
    <>
      {props.canvasLayer.children}
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
          zIndex: 100,
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            background:
              currPage == 0
                ? "none"
                : props.pageIndex == currPage - 1
                  ? "rgba(250, 244, 211, 0.7)"
                  : "rgba(190, 190, 190, 0.7)",
          }}
        >
          {feedback.page && currPage - 1 == props.pageIndex
            ? feedbackOverlay()
            : noClassificationOverlay()}
        </div>
      </div>
      {props.annotationLayer.children}
      {props.textLayer.children}
    </>
  );

  const noClassificationOverlay = () => {
    return <></>;
  };
  const feedbackOverlay = () => {
    return (
      <div
        style={{
          width: "fit-content",
          marginTop: "20px",
        }}
      >
        <PageFeedbackReview />
      </div>
    );
  };

  if (!fileUrl) return <></>;

  return (
    <Flex style={{ height: "calc(100vh - 130px)" }}>
      <div style={{ width: "250px", paddingTop: "10px" }}>
        <Thumbnails />
      </div>
      <Container height="calc(100vh - 115px)" width="100%" overflow="hidden">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.js">
          <Viewer
            defaultScale={SpecialZoomLevel.PageWidth}
            fileUrl={fileUrl}
            plugins={[thumbnailPluginInstance, pageNavigationPluginInstance]}
            initialPage={currPage - 1}
            onDocumentLoad={(e) => {
              console.log(e);

              setIsDocumentLoaded(true);
            }}
            onPageChange={(e) => {
              setCurrentPage(e.currentPage);
            }}
            renderPage={renderPage}
          />
        </Worker>
      </Container>
    </Flex>
  );
};

export default FeedbackReviewPDFViewer;
