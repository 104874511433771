import { Fab, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { Add, Close, Delete, Search } from "@mui/icons-material";
import { useContext, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Flex from "styled-flex-component";
import { Theme } from "../../../theme";
import SearchClause from "./SearchClause";
import { Text } from "../../UI/Typography";
import {
  GetInsightsContext,
  GetInsightsContextType,
} from "../../../pages/GetInsights/context/getInsightsContext";

const MenuFab = () => {
  const { isAddingNewRow, loadingInsights, setIsAddingNewRow, setSearchKey } =
    useContext(GetInsightsContext) as GetInsightsContextType;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const actions = [
    {
      actionIcon: isAddingNewRow ? <Delete /> : <Add />,
      iconColor: isAddingNewRow ? "red" : "green",
      leftSlot: (
        <Text customFontSize={1} color="gray">
          {isAddingNewRow ? "Discard" : "Add"} new attribute
        </Text>
      ),
      actionMethod: () => {
        setIsAddingNewRow(!isAddingNewRow);
        handleClose();
      },
    },
    {
      actionIcon: <Search />,
      iconColor: "orange",
      leftSlot: <SearchClause isDisabled={false} />,
      actionMethod: () => {
        setIsAddingNewRow(false);
      },
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl == null ? event.currentTarget : null);
    setSearchKey("");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const FabMenuItem = (action, index) => {
    return (
      <MenuItem
        onClick={() => {
          action.actionMethod();
        }}
        onKeyDown={(e) => e.stopPropagation()}
        sx={{
          display: "flex",
          justifyContent: "end",
          height: "65px",
          marginTop: index == 0 ? "0px" : "5px",
        }}
      >
        {action.leftSlot}
        <IconButton
          size="large"
          style={{
            background: action.iconColor,
            color: "white",
            marginLeft: 10,
          }}
        >
          {action.actionIcon}
        </IconButton>
      </MenuItem>
    );
  };

  return (
    <>
      <Flex justifyCenter style={{ background: "transparent", width: "70px" }}>
        <Fab
          aria-label="Actions"
          onClick={handleClick}
          color="primary"
          style={{ background: "transparent" }}
        >
          <Tooltip
            title={anchorEl == null ? "Open actions" : "Close"}
            placement="left"
          >
            <IconButton
              size="large"
              style={{ color: "white", background: Theme.primary }}
              disabled={loadingInsights}
              sx={{ "&:hover": { opacity: "80%" } }}
            >
              {anchorEl == null ? <MenuIcon /> : <Close />}
            </IconButton>
          </Tooltip>
        </Fab>
      </Flex>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
      >
        {actions.map((action, index) => {
          return FabMenuItem(action, index);
        })}
      </Menu>
    </>
  );
};

export default MenuFab;
