import { Button } from "@mui/material";

interface IUnassignButtonProps {
  unassign: (assignment_id: string, parent_assignment_id: string) => void;
  document: any;
}

const UnassignButton = ({ document, unassign }: IUnassignButtonProps) => {
  return (
    <Button
      variant="text"
      onClick={() => {
        unassign(document.assignment_id, "");
      }}
    >
      Unassign
    </Button>
  );
};
export default UnassignButton;
