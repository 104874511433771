import { VisibilityOff } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";

interface INotFoundButtonProps {
  notFound: () => void;
}

const NotFoundButton = ({ notFound }: INotFoundButtonProps) => {
  return (
    <Tooltip title="Mark insight as not found">
      <Button
        color="warning"
        variant="contained"
        onClick={() => notFound()}
        style={{
          display: "flex",
          gap: "5px",
        }}
      >
        <VisibilityOff fontSize="small" />
      </Button>
    </Tooltip>
  );
};

export default NotFoundButton;
