import GetInsightsSidebarStyled from "./Sidebar.styled";
import ClausesList from "./ClausesList";

const InsightsSidebar = () => {
  return (
    <GetInsightsSidebarStyled>
      <ClausesList />
    </GetInsightsSidebarStyled>
  );
};

export default InsightsSidebar;
