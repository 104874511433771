import { isEmpty } from "lodash";
import DocumentTypeChip from "../../../UI/DocumentTypeChip/DocumentTypeChip";
import Flex from "styled-flex-component";

interface IPacketTypeProps {
  document: any;
  isUnassigned: boolean;
  isPages?: boolean;
}

const PacketType = ({ document, isPages }: IPacketTypeProps) => {
  return (
    <Flex
      justifyCenter
      style={{
        width: "15%",
      }}
    >
      {(isPages ||
        !isEmpty(document.doc_type) ||
        !isEmpty(document.document_type)) && (
        <DocumentTypeChip
          label={
            isPages ? "Pages" : document.doc_type || document.document_type
          }
          isPrimary={!isPages && (document.doc_type || document.document_type)}
          maxWidth="13vw"
        />
      )}
    </Flex>
  );
};
export default PacketType;
