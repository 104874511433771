import lithClient from "./instance";

export const grabDocument = async (doc_id) => {
  return lithClient
    .post(`/documents/${doc_id}/assign/self`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
};

export const grabPage = async (doc_id, page_id) => {
  return lithClient
    .post(`/documents/${doc_id}/pages/${page_id}/assign/self`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
};

export const unassignDocument = async (doc_id) => {
  return lithClient
    .delete(`/documents/${doc_id}/unassign/self`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
};

export const unassignPage = async (doc_id, page_id) => {
  return lithClient
    .delete(`/documents/${doc_id}/pages/${page_id}/unassign/self`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
};

export const unassignTask = async (assignment_id) => {
  return lithClient
    .delete(`/tasks/${assignment_id}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
};

export const submit = async (assignment_id, status?: string) => {
  return lithClient
    .put(`/tasks/${assignment_id}`, {
      status: status ?? "submit",
    })
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err.response }));
};
