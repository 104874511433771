import { Button, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";

interface IAddDefinitionButtonProps {
  addDefinition: () => void;
}

const AddDefinitionButton = ({ addDefinition }: IAddDefinitionButtonProps) => {
  return (
    <Tooltip title="Add a definition">
      <Button
        color="success"
        onClick={(e) => {
          e.stopPropagation();
          addDefinition();
        }}
        variant="contained"
      >
        <Add fontSize="small" />
      </Button>
    </Tooltip>
  );
};

export default AddDefinitionButton;
