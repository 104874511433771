import CardContainer from "../../components/UI/Common/Card/Card";
import Container from "../../components/UI/Common/Container";
import DocumentsTable from "../../components/Documents/Tables/Documents";
import { Box, Button, CircularProgress, Tab, Tabs } from "@mui/material";
import Flex from "styled-flex-component";
import { MeliorTable } from "../../components/MeliorTable";
import { useContext, useEffect, useState } from "react";
import {
  createPage,
  getAssigned,
  getAssignedToOthers,
  getUnassignedDocuments,
  getUnassignedPages,
} from "../../api/files.api";
import { grabDocument, grabPage, unassignTask } from "../../api/grab.api";
import { Text } from "../../components/UI/Typography";
import { Theme } from "../../theme";
import { isEmpty } from "lodash";
import { FilterContext, FilterContextType } from "../../context/FilterContext";
import { getTags } from "../../api/tags.api";

const DocumentsPage = () => {
  const { currTag, showTestData, setAllTags } = useContext(
    FilterContext
  ) as FilterContextType;
  const [listType, setListType] = useState<string>("Assigned to you");
  const [backlogType, setBacklogType] = useState<string>("Documents");
  // Lists:
  const [backlogs, setBacklogs] = useState<any[]>([]);
  const [backlogsPages, setBacklogsPages] = useState<any[]>([]);
  const [assignedToOthersList, setAssignedToOthersList] = useState<any[]>([]);
  const [assignedList, setAssignedList] = useState<any[]>([]);
  const [currList, setCurrList] = useState<any[]>([]);

  const [assignedTotal, setAssignedTotal] = useState<number>(0);
  // const [assignedToOthersTotal, setAssignedToOthersTotal] = useState<number>(0);
  // Loading:
  const [isLoadingBacklogs, setIsLoadingBacklogs] = useState(false);
  const [isLoadingAssigned, setIsLoadingAssigned] = useState(false);
  const [isLoadingAssignedToOthers, setIsLoadingAssignedToOthers] =
    useState(false);
  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [emptyMsg, setEmptyMsg] = useState("");

  useEffect(() => {
    if (listType == "Assigned to you") {
      setIsLoading(isLoadingAssigned && isEmpty(assignedList));
      setEmptyMsg("No assigned items. Grab from Backlog.");
    } else if (listType == "Assigned to others") {
      setIsLoading(isLoadingAssignedToOthers && isEmpty(assignedToOthersList));
      setEmptyMsg("No assigned items to others.");
    } else if (listType == "Backlogs") {
      setIsLoading(
        isLoadingBacklogs && (isEmpty(backlogs) || isEmpty(backlogsPages))
      );
      setEmptyMsg("No items to work on");
    } else setIsLoading(false);
  }, [listType, backlogType]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [currTag]);

  const refetch = () => {
    fetchAssigned();
    fetchAssignedToOthers();
    fetchUnassignedDocs();
    fetchUnassignedPages();
    getAllTags();
  };

  useEffect(() => {
    updateList();
  }, [
    backlogType,
    listType,
    backlogs,
    backlogsPages,
    assignedList,
    assignedToOthersList,
    showTestData,
  ]);

  const getAllTags = () => {
    getTags()?.then((res) => {
      setAllTags(res);
    });
  };

  const getCurrentList = () => {
    return listType == "Backlog"
      ? backlogType == "Documents"
        ? backlogs
        : backlogsPages
      : listType == "Assigned to you"
        ? assignedList
        : assignedToOthersList;
  };

  const updateList = () => {
    const list = getCurrentList();
    const newList = getToggledList(list);
    setCurrList(newList);
    setCounter(newList.length);
  };

  const getToggledList = (list) => {
    const temp: any = list.concat([]);
    return showTestData
      ? temp
      : temp.filter((doc) => !doc.doc_name.toLowerCase().includes("test"));
  };

  const fetchUnassignedDocs = () => {
    setIsLoadingBacklogs(true);
    getUnassignedDocuments(currTag).then((res) => {
      setBacklogs(res);
      setIsLoadingBacklogs(false);
    });
  };

  const fetchUnassignedPages = () => {
    setIsLoadingBacklogs(true);
    getUnassignedPages(currTag).then((res) => {
      setBacklogsPages(res);
      setIsLoadingBacklogs(false);
    });
  };

  const fetchAssigned = () => {
    setIsLoadingAssigned(true);
    getAssigned(currTag).then((res: any) => {
      setAssignedTotal(res.total);
      setAssignedList(res.transformed);
      setIsLoadingAssigned(false);
    });
  };

  const fetchAssignedToOthers = () => {
    setIsLoadingAssigned(true);
    getAssignedToOthers(currTag).then((res: any) => {
      // setAssignedToOthersTotal(res.total);
      setAssignedToOthersList(res.transformed);
      setIsLoadingAssignedToOthers(false);
    });
  };

  const grab = (doc_id, page_index, page_id) => {
    if (page_index) {
      if (page_id) {
        doGrabPage(doc_id, page_index, page_id);
      } else {
        createPage(doc_id, page_index).then((res) => {
          const page_id = res.page_id;
          doGrabPage(doc_id, page_index, page_id);
        });
      }
    } else {
      //grab document
      grabDocument(doc_id).then(() => {
        fetchAssigned();
      });
      //remove document from backlog
      setBacklogs(backlogs.filter((backlog) => backlog.doc_id != doc_id));
    }
  };

  const unassign = (assignment_id, parent_assignment_id) => {
    unassignTask(assignment_id).then(async () => {
      fetchUnassignedDocs();
      fetchUnassignedPages();
    });

    if (parent_assignment_id) {
      // find parent doc
      const docIndex = assignedList.findIndex(
        (assigned) => assigned.assignment_id == parent_assignment_id
      );

      if (assignedList[docIndex].pages.length > 1) {
        // create a new list of pages without current page
        const updatedPages = assignedList[docIndex].pages.filter(
          (page) => page.assignment_id != assignment_id
        );

        //update parent doc's pages with new list
        const updatedList = assignedList.concat([]);
        updatedList[docIndex].pages = updatedPages;

        //update assigned list
        setAssignedList(updatedList);
      } else {
        setAssignedList(
          assignedList.filter(
            (assigned) => assigned.assignment_id != parent_assignment_id
          )
        );
      }
    } else {
      setAssignedList(
        assignedList.filter(
          (assigned) => assigned.assignment_id != assignment_id
        )
      );
    }
    setAssignedTotal(assignedTotal - 1);
  };

  const doGrabPage = (doc_id, page_index, page_id) => {
    grabPage(doc_id, page_id).then(() => {
      fetchAssigned();
    });
    // find parent doc
    const docIndex = backlogsPages.findIndex(
      (backlog) => backlog.doc_id == doc_id
    );
    if (backlogsPages[docIndex].pages.length > 1) {
      // create a new list of pages without current page
      const updatedPages = backlogsPages[docIndex].pages.filter(
        (page) => page.page_index != page_index
      );

      //update parent doc's pages with new list
      const updatedBacklogsPages = backlogsPages.concat([]);
      updatedBacklogsPages[docIndex].pages = updatedPages;

      //update backlogs pages
      setBacklogsPages(updatedBacklogsPages);
    } else {
      setBacklogsPages(
        backlogsPages.filter((backlog) => backlog.doc_id != doc_id)
      );
    }
  };

  return (
    <Container outerSpacing={1}>
      <CardContainer>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Flex justifyBetween alignCenter>
            {/* Tabs */}
            <Flex alignCenter style={{ gap: "5px" }}>
              {/* Tabs */}
              {listType == "Backlog" && (
                <Tabs value={backlogType} style={{ overflow: "visible" }}>
                  <Tab
                    label="Documents"
                    value="Documents"
                    onClick={() => setBacklogType("Documents")}
                  />
                  <Tab
                    label="Pages"
                    value="Pages"
                    onClick={() => setBacklogType("Pages")}
                  />
                </Tabs>
              )}
              {/* Counter */}
              <Text
                color="white"
                style={{
                  background: Theme.primary,
                  borderRadius: "20px",
                  height: "fit-content",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  paddingRight: "9px",
                  paddingLeft: "9px",
                  marginRight: "15px",
                }}
              >
                {counter}
              </Text>
            </Flex>
            {/* Ticket Types */}
            <Flex alignCenter style={{ gap: "20px", marginBottom: "10px" }}>
              {Boolean(
                listType == "Backlog"
                  ? isLoadingBacklogs && backlogs.length && backlogsPages.length
                  : listType == "Assigned to you"
                    ? isLoadingAssigned && assignedList.length
                    : isLoadingAssignedToOthers && assignedToOthersList.length
              ) && (
                <CircularProgress
                  style={{
                    color: "#3f51b5",
                    width: "20px",
                    height: "20px",
                  }}
                />
              )}
              <div
                style={{
                  background: "whitesmoke",
                  borderRadius: "5px",
                  border: "1px solid #3874CB",
                }}
              >
                <Button
                  variant={listType == "Backlog" ? "contained" : "text"}
                  onClick={() => setListType("Backlog")}
                  style={{
                    borderRadius: "0px",
                  }}
                >
                  Backlog
                </Button>
                <Button
                  variant={
                    listType == "Assigned to others" ? "contained" : "text"
                  }
                  onClick={() => setListType("Assigned to others")}
                  style={{
                    borderRight: "1px solid",
                    borderLeft: "1px solid",
                    borderColor: "#3874CB",
                    borderRadius: "0px",
                  }}
                >
                  Assigned to others
                </Button>
                <Button
                  variant={listType == "Assigned to you" ? "contained" : "text"}
                  onClick={() => setListType("Assigned to you")}
                  style={{
                    borderRadius: "0px",
                  }}
                >
                  Assigned to you
                </Button>
              </div>
            </Flex>
          </Flex>
        </Box>
        <MeliorTable
          showLoading={isLoading}
          error={""}
          items={currList}
          tableEl={
            <DocumentsTable
              documents={currList}
              refetch={() => refetch()}
              grab={(doc_id, page_id, isExisting) =>
                grab(doc_id, page_id, isExisting)
              }
              unassign={(assignment_id, parent_assignment_id) =>
                unassign(assignment_id, parent_assignment_id)
              }
              isUnassigned={listType == "Backlog"}
              isAssignedToOthers={listType == "Assigned to others"}
            />
          }
          loadingMessage="Loading items"
          emptyMessage={emptyMsg}
          errorMessage="An error has occurred"
        />
      </CardContainer>
    </Container>
  );
};

export default DocumentsPage;
