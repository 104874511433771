import { Theme } from "../../../theme";
import Container from "../../UI/Common/Container";
import Flex from "styled-flex-component";
import { Text } from "../../UI/Typography";
import { useContext } from "react";
import {
  InsightsReadOnlyContext,
  InsightsReadOnlyContextType,
} from "../../../pages/InsightsReadOnlyPage/context/insightsReadOnlyContext";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import DocumentFeedbackSelector from "../DocumentFeedbackSelector";

const GetInsightsHeader = () => {
  const { packetData } = useContext(
    InsightsReadOnlyContext
  ) as InsightsReadOnlyContextType;

  const navigate = useNavigate();
  const showDocFeedback =
    //has doc type
    packetData.type_name &&
    //not classified
    packetData.status != "classified" &&
    packetData.status != "classified_annotated";

  return (
    <Container
      fixed
      width="100%"
      zIndex={1}
      backgroundColor={Theme.primaryDark}
      height={4.5}
    >
      <Container
        leftInnerSpacing={2}
        rightInnerSpacing={2.6875}
        topInnerSpacing={0.75}
        bottomInnerSpacing={0.75}
        color={Theme.whiteColor}
      >
        <Flex justifyBetween alignCenter>
          <Flex alignCenter style={{ gap: "10px", height: "45px" }}>
            <Container
              rightOuterSpacing={0.75}
              topOuterSpacing={0.4}
              cursor="pointer"
              onClick={() => {
                window.history?.length > 1 ? navigate(-1) : navigate("/");
                localStorage.setItem("currentMatchIndex", "0");
              }}
            >
              <ChevronLeft style={{ color: Theme.whiteColor }} />
            </Container>
            <Container maxWidth={30}>
              <Text
                customFontWeight={600}
                data-testid="header-document-name"
                customFontSize={1}
                color={Theme.whiteColor}
                whiteSpace="break-spaces"
                wordBreak="break-all"
              >
                {packetData.name}
              </Text>
            </Container>
            {showDocFeedback && <DocumentFeedbackSelector />}
          </Flex>
        </Flex>
      </Container>
    </Container>
  );
};
export default GetInsightsHeader;
