import lithClient from "./instance";

export function createFeedback(doc_id, page_id, payload) {
  return lithClient
    .post(`/documents/${doc_id}/pages/${page_id}/feedback`, payload)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}

export function getDocumentPageFeedbacks(doc_id) {
  return lithClient
    .get(`/feedback/docs/${doc_id}/pages`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}

export async function createDocFeedback(doc_id, payload) {
  return lithClient
    .post(`/documents/${doc_id}/feedback`, payload)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}

export async function getDocumentFeedback(doc_id) {
  return lithClient
    .get(`/documents/${doc_id}/feedback`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}

export async function getPageFeedbackList() {
  return lithClient
    .get(`/feedback/pages`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}

export async function getDocumentFeedbackList() {
  return lithClient
    .get(`/feedback/docs`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}

export async function acceptDocFeedback(feedback_id) {
  return lithClient
    .put(`/feedback/${feedback_id}/docs`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}

export async function rejectDocFeedback(feedback_id) {
  return lithClient
    .delete(`/feedback/${feedback_id}/docs`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}

export function acceptPageFeedback(feedback_id) {
  return lithClient
    .put(`/feedback/${feedback_id}/pages`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}

export function rejectPageFeedback(feedback_id) {
  return lithClient
    .delete(`/feedback/${feedback_id}/pages`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err }));
}
