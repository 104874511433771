import lithClient from "./instance";

export async function getDocumentInsights(doc_id) {
  return lithClient
    .get(`/documents/${doc_id}/insights`)
    .then((res) => res.data)
    .catch(() => []);
}
export function saveDocumentInsight(doc_id, payload) {
  return lithClient
    .post(`/documents/${doc_id}/insights`, payload)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err.response }));
}

export function updateDocumentInsight(doc_id, insight_id, payload) {
  return lithClient
    .put(`/documents/${doc_id}/insights/${insight_id}`, payload)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err.response }));
}

export function deleteDocumentInsight(doc_id, insight_id) {
  return lithClient
    .delete(`/documents/${doc_id}/insights/${insight_id}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject({ ...err.response }));
}
