import Flex from "styled-flex-component";
import { Text } from "../../UI/Typography";
import Container from "../../UI/Common/Container";
import { isEmpty } from "lodash";
import { Theme } from "../../../theme";
import { CircularProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  InsightsReadOnlyContext,
  InsightsReadOnlyContextType,
} from "../../../pages/InsightsReadOnlyPage/context/insightsReadOnlyContext";
import ClausesListItem from "./ClausesListItem";
import TextMatchesNavigation from "./TextMatchesNavigation";
import SearchClause from "./SearchClause";
import SuggestedAnswer from "./SuggestedAnswer";

const ClausesList = () => {
  const {
    selectedInsight,
    editSelectedClause,
    selectedRegion,
    insights,
    loadingInsights,
    textMatchIndex,
    hasNewBlankAnswer,
    searchKey,
    setSelectedRegion,
    setEditSelectedClause,
    setSelectedInsight,
    setTextMatchIndex,
    setCurrentInsight,
  } = useContext(InsightsReadOnlyContext) as InsightsReadOnlyContextType;
  const [hoveredInsight, setHoveredInsight] = useState<string>();
  const [displayedClauses, setDisplayedClauses] = useState<Array<any>>([]);
  const [allClauses, setAllClauses] = useState<Array<any>>([]);
  const [internalDoneAnnotated, setInternalDoneAnnotated] = useState<any>([]);
  const isEditing = (key) =>
    Boolean(editSelectedClause && editSelectedClause.clause !== key);

  useEffect(() => {
    if (insights) {
      const groupedInsights = groupInsights(insights);
      const clausesWithId = attachClauseId(groupedInsights, 0);
      setInternalDoneAnnotated(clausesWithId);
      setAllClauses(clausesWithId);
      setDisplayedClauses(clausesWithId);
    }
  }, [insights]);

  useEffect(() => {
    const filteredBySearchKey = allClauses.filter((clause) =>
      clause.clause_name.toLowerCase().includes(searchKey.toLowerCase())
    );
    setDisplayedClauses(filteredBySearchKey);
  }, [searchKey]);

  const groupInsights = (insights) => {
    let groupedInsights: any = [];
    insights.forEach((insight) => {
      const parentInsightIndex = groupedInsights.findIndex(
        (groupedInsight: any) =>
          groupedInsight.clause_name == insight.clause_name
      );

      if (parentInsightIndex != -1)
        groupedInsights[parentInsightIndex].answers =
          groupedInsights[parentInsightIndex].answers.concat(insight);
      else
        groupedInsights = groupedInsights.concat({
          clause_name: insight.clause_name,
          answers: [insight],
        });
    });

    groupedInsights.forEach((group) => {
      if (group.answers.length > 1) {
        group.answers = group.answers.sort(
          (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at)
        );
      }
    });

    const currInsight = groupedInsights.find(
      (insight) => insight.clause_name == selectedInsight
    );
    setCurrentInsight(currInsight);

    return groupedInsights;
  };

  const attachClauseId = (clauses, startIndex) => {
    if (!clauses) return [];
    const clausesWithId: Array<any> = [];

    clauses.forEach((item, i) => {
      clausesWithId.push({
        id: (startIndex + i + 1).toString(),
        ...item,
      });
      return;
    });
    return clausesWithId;
  };

  const editSelection = (e, clause) => {
    e.stopPropagation();
    setEditSelectedClause({
      clause: clause.clause_name,
      text: clause.text,
    });
    setSelectedRegion(undefined);
  };

  const getAnswerStatus = (answer) => {
    if (answer.text == "")
      return {
        status: "Not Found",
        displayText: answer.updated_by
          ? `Marked as not found by ${answer.updated_by.email}`
          : "",
      };
    if (answer.created_by && answer.updated_by) {
      //Same create and update - Added
      if (answer.created_by.email == answer.updated_by.email)
        return {
          status: "New",
          displayText: `Added by ${answer.created_by.email}`,
        };
      //Create by someone and updated by someone else - Edited
      else {
        return {
          status: "Edited",
          displayText: `Edited by ${answer.updated_by.email}`,
        };
      }
    } else {
      if (!answer.created_by) {
        //No create and have update - Edited
        if (answer.updated_by) {
          return {
            status: "Edited",
            displayText: `Edited by ${answer.updated_by.email}`,
          };
        }
        //No create and no update - none
        else {
          return {
            status: "Extracted",
            displayText: "",
          };
        }
      } else {
        //Have create and no update - Added
        return {
          status: "New",
          displayText: `Added by ${answer.created_by.email}`,
        };
      }
    }
  };

  return (
    <div
      style={{
        height: "100%",
        marginTop: "9px",
      }}
    >
      <Flex
        alignCenter
        style={{ padding: "10px", background: "whitesmoke", gap: "10px" }}
      >
        <SearchClause
          isDisabled={
            displayedClauses.find((clause) => clause.insight_id == "new") ||
            loadingInsights ||
            hasNewBlankAnswer
          }
        />
      </Flex>
      <div
        style={{
          overflowY: "scroll",
          height: "100%",
        }}
      >
        {isEmpty(insights) && (
          <Flex
            justifyCenter
            style={{
              marginTop: "20px",
            }}
          >
            {!loadingInsights ? (
              <>No extractions found.</>
            ) : (
              <CircularProgress
                style={{
                  color: "#3f51b5",
                  width: "30px",
                  height: "30px",
                }}
              />
            )}
          </Flex>
        )}

        {displayedClauses.map((clause, index) => (
          <Container key={clause.clause_name}>
            {selectedInsight !== clause.clause_name && (
              <div>
                <ClausesListItem
                  index={index}
                  clause={clause}
                  hoveredInsight={hoveredInsight as string}
                  setHoveredInsight={setHoveredInsight}
                  isEditing={isEditing}
                  isHideable={true}
                  editSelection={editSelection}
                  isAnnotated={internalDoneAnnotated.includes(
                    clause.clause_name
                  )}
                  getAnswerStatus={getAnswerStatus}
                />
              </div>
            )}
            {selectedInsight === clause.clause_name && (
              <Container
                borderLeft={`4px solid ${Theme.primary}`}
                borderBottom="1px solid rgba(13, 24, 50, 0.12)"
                leftInnerSpacing={2}
                topInnerSpacing={2}
                bottomInnerSpacing={1.5}
                rightInnerSpacing={1.625}
                onClick={() => {
                  setTextMatchIndex(0);
                  setSelectedInsight("");
                }}
                backgroundColor="white"
              >
                <>
                  <Text customFontWeight={600} color={Theme.primaryDark}>
                    {clause.clause_name}
                  </Text>

                  <Container
                    topOuterSpacing={1.375}
                    backgroundColor={Theme.background}
                    leftInnerSpacing={0.75}
                    topInnerSpacing={1}
                    bottomInnerSpacing={1}
                    rightInnerSpacing={0.75}
                    wordBreak
                  >
                    {clause.answers[textMatchIndex].text == "" &&
                      editSelectedClause?.clause != clause.clause_name && (
                        <i style={{ opacity: 0.4 }}>empty</i>
                      )}
                    {!isEmpty(clause.clause_name) && (
                      <Flex column justifyBetween>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            minHeight: "6.09375rem",
                            gap: "10px",
                          }}
                        >
                          <Text
                            customFontSize={0.8125}
                            customFontWeight={400}
                            style={{
                              fontStyle:
                                clause.answers[textMatchIndex]?.insight_id ==
                                "blank_answer"
                                  ? "italic"
                                  : "normal",
                            }}
                            color={
                              clause.answers[textMatchIndex]?.insight_id ==
                              "blank_answer"
                                ? "gray"
                                : "black"
                            }
                          >
                            {selectedRegion
                              ? selectedRegion.text
                              : clause.answers[textMatchIndex]?.text}
                          </Text>
                          {clause.answers[textMatchIndex].suggested_answer &&
                            editSelectedClause?.clause !=
                              clause.clause_name && (
                              <SuggestedAnswer clause={clause} />
                            )}
                          {!hasNewBlankAnswer && (
                            <Flex justifyBetween alignCenter>
                              <Text customFontSize={0.65} color="gray">
                                {
                                  getAnswerStatus(
                                    clause.answers[textMatchIndex]
                                  ).displayText
                                }
                              </Text>
                            </Flex>
                          )}
                        </div>
                        {clause.answers.length > 1 && (
                          <TextMatchesNavigation
                            items={clause.answers}
                            hasNewBlankAnswer={hasNewBlankAnswer}
                          />
                        )}
                      </Flex>
                    )}
                  </Container>
                </>
              </Container>
            )}
          </Container>
        ))}
      </div>
    </div>
  );
};

export default ClausesList;
