import React from "react";
import Loading from "../UI/Common/Loading";
import { useTranslation } from "react-i18next";
import ErrorPlaceholder from "../UI/Common/ErrorPlaceholder";
import Empty from "../UI/Common/Empty";

interface Props {
  showLoading: boolean;
  error: unknown;
  items: any[];
  loadingMessage: string;
  errorMessage: string;
  tableEl: React.ReactNode;
  emptyMessage: React.ReactNode | string;
}

export const MeliorTable = ({
  showLoading,
  error,
  items,
  loadingMessage,
  errorMessage,
  tableEl,
  emptyMessage,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {showLoading && <Loading message={t(loadingMessage)} marginTop="80px" />}
      {!showLoading && error && <ErrorPlaceholder message={t(errorMessage)} />}
      {!showLoading && !error && !Boolean(items.length) && (
        <Empty message={emptyMessage} />
      )}
      {!showLoading && !error && Boolean(items.length) && <>{tableEl}</>}
    </>
  );
};
