import CardContainer from "../../components/UI/Common/Card/Card";
import Container from "../../components/UI/Common/Container";
import { Badge, Box, Button, CircularProgress, Tab, Tabs } from "@mui/material";
import Flex from "styled-flex-component";
import { MeliorTranslate } from "../../components/MeliorTranslate";
import { MeliorTable } from "../../components/MeliorTable";
import { useEffect, useState } from "react";
import {
  getDocumentFeedbackList,
  getPageFeedbackList,
} from "../../api/feedback.api";
import FeedbacksTable from "../../components/Feedbacks/Tables/Feedbacks";
import { getUserSessionData } from "../../utils/userSession";

const FeedbacksPage = () => {
  const [listType, setListType] = useState<string>("Documents");
  const [documentFeedbacks, setDocumentFeedbacks] = useState<any[]>([]);
  const [pageFeedbacks, setPageFeedbacks] = useState<any[]>([]);
  const [isLoadingFeedbacks, setIsLoadingFeedbacks] = useState(false);
  const session = getUserSessionData();

  useEffect(() => {
    refetch();
  }, []);

  const refetch = () => {
    fetchDocumentFeedbacks();
    fetchPageFeedbacks();
  };

  const fetchDocumentFeedbacks = () => {
    setIsLoadingFeedbacks(true);
    getDocumentFeedbackList().then((res) => {
      setDocumentFeedbacks(
        res.filter(
          (feedback) =>
            feedback.user_id != session.user.id && feedback.status == null
        )
      );
      setIsLoadingFeedbacks(false);
    });
  };

  const fetchPageFeedbacks = () => {
    setIsLoadingFeedbacks(true);
    getPageFeedbackList().then((res) => {
      setPageFeedbacks(
        res.filter(
          (feedback) =>
            feedback.user_id != session.user.id && feedback.status == null
        )
      );
      setIsLoadingFeedbacks(false);
    });
  };

  return (
    <Container outerSpacing={1}>
      <CardContainer>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Flex justifyBetween alignCenter>
            <Tabs value={0} style={{ overflow: "visible" }}>
              <Tab
                label={
                  <Flex alignCenter>
                    <span>
                      <MeliorTranslate valueKey="Packet" />
                    </span>
                    <Container leftOuterSpacing={1}>
                      <Badge
                        badgeContent={
                          listType == "Documents"
                            ? documentFeedbacks.length
                            : pageFeedbacks.length
                        }
                        color="primary"
                      />
                    </Container>
                  </Flex>
                }
              />
              <div
                style={{
                  background: "whitesmoke",
                  marginBottom: "10px",
                  borderRadius: "5px",
                  border: "1px solid #0288D1",
                  marginLeft: "30px",
                }}
              >
                <Button
                  variant={listType == "Documents" ? "contained" : "text"}
                  onClick={() => setListType("Documents")}
                  color="info"
                >
                  Documents
                </Button>
                <Button
                  variant={listType == "Pages" ? "contained" : "text"}
                  onClick={() => setListType("Pages")}
                  color="info"
                >
                  Pages
                </Button>
              </div>
            </Tabs>
            <Flex alignCenter style={{ gap: "20px", marginBottom: "10px" }}>
              {Boolean(
                isLoadingFeedbacks &&
                  documentFeedbacks.length &&
                  pageFeedbacks.length
              ) && (
                <CircularProgress
                  style={{
                    color: "#3f51b5",
                    width: "20px",
                    height: "20px",
                  }}
                />
              )}
              {/* <div
                style={{
                  background: "whitesmoke",
                  borderRadius: "5px",
                  border: "1px solid #3874CB",
                }}
              >
                <Button
                  variant={listType == "Backlog" ? "contained" : "text"}
                  onClick={() => setListType("Backlog")}
                >
                  Backlog
                </Button>
                <Button
                  variant={listType == "Assigned to you" ? "contained" : "text"}
                  onClick={() => setListType("Assigned to you")}
                >
                  Assigned to you
                </Button>
              </div> */}
            </Flex>
          </Flex>
        </Box>
        <MeliorTable
          showLoading={Boolean(
            isLoadingFeedbacks &&
              (!documentFeedbacks.length || !pageFeedbacks.length)
          )}
          error={""}
          items={listType == "Documents" ? documentFeedbacks : pageFeedbacks}
          tableEl={
            <FeedbacksTable
              feedbacks={
                listType == "Documents" ? documentFeedbacks : pageFeedbacks
              }
              refetch={() => refetch()}
              listType={listType}
            />
          }
          loadingMessage="Loading feedbacks"
          emptyMessage={"No feedbacks to review"}
          errorMessage="An error has occurred"
        />
      </CardContainer>
    </Container>
  );
};

export default FeedbacksPage;
