import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface IAnnotateButtonProps {
  document: any;
}

const AnnotateButton = ({ document }: IAnnotateButtonProps) => {
  const navigate = useNavigate();
  return (
    <Button
      variant="outlined"
      style={{ width: "135px" }}
      onClick={() => {
        navigate(`/insights/${document.doc_id}`, {
          state: {
            packetData: {
              ...document,
            },
          },
        });
      }}
    >
      Annotate
    </Button>
  );
};
export default AnnotateButton;
