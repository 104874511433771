import Flex from "styled-flex-component";
import { Text } from "../../UI/Typography";
import Container from "../../UI/Common/Container";
import { Theme } from "../../../theme";
import {
  InsightsReadOnlyContext,
  InsightsReadOnlyContextType,
} from "../../../pages/InsightsReadOnlyPage/context/insightsReadOnlyContext";
import { useContext } from "react";

interface ICLausesListItemProps {
  index: number;
  clause: any;
  hoveredInsight: string;
  setHoveredInsight: (string) => void;
  isEditing: (string) => boolean;
  isHideable: boolean;
  editSelection: (e: any, clause: any) => void;
  isAnnotated: boolean;
  getAnswerStatus: (answer: any) => any;
}

const ClausesListItem = ({
  clause,
  hoveredInsight,
  setHoveredInsight,
  isEditing,
  isHideable,
  getAnswerStatus,
}: ICLausesListItemProps) => {
  const {
    setSelectedInsight,
    setSelectedInsightId,
    setTextMatchIndex,
    setCurrentInsight,
  } = useContext(InsightsReadOnlyContext) as InsightsReadOnlyContextType;
  const isEdited =
    clause.answers &&
    clause.answers.find(
      (answer) => getAnswerStatus(answer).status == "Edited"
    ) != undefined;
  const isNew =
    clause.answers &&
    clause.answers.find((answer) => getAnswerStatus(answer).status == "New") !=
      undefined;
  const isNotFound = clause.answers && clause.answers[0].text == "";
  return (
    <Container
      onClick={() => {
        setTextMatchIndex(0);
        setSelectedInsight(clause.clause_name);
        setSelectedInsightId(clause.answers[0].insight_id);
        setCurrentInsight(clause);
      }}
      pointerEvents={isEditing(clause.clause_name) && isHideable ? "none" : ""}
      backgroundColor={
        isEditing(clause.clause_name) && isHideable
          ? "rgba(13, 24, 50, 0.3)"
          : hoveredInsight == clause.clause_name
            ? "whitesmoke"
            : "white"
      }
      display="flex"
      data-testid="clause-row"
      cursor="pointer"
      borderBottom="1px solid rgba(13, 24, 50, 0.12)"
      leftInnerSpacing={2}
      rightInnerSpacing={1}
      minHeight={3}
      topInnerSpacing={1}
      bottomInnerSpacing={1}
      onMouseEnter={() => {
        setHoveredInsight(clause.clause_name);
      }}
      onMouseLeave={() => {
        setHoveredInsight("");
      }}
    >
      <Flex alignCenter>
        <Flex style={{ gap: "5px" }} alignStart>
          <Text
            color={Theme.primaryDark}
            style={{
              width: "fit-content",
              maxWidth: "70%",
              height: "100%",
            }}
          >
            {clause.clause_name}
          </Text>

          {(isEdited || isNew || isNotFound) && (
            <Text
              customFontSize={0.7}
              style={{
                background:
                  isEditing(clause.clause_name) && isHideable
                    ? "gray"
                    : isNotFound
                      ? "gray"
                      : isEdited
                        ? "orange"
                        : "green",
                textAlign: "center",
                padding: "2px",
                paddingRight: "5px",
                paddingLeft: "5px",
                borderRadius: "10px",
                whiteSpace: "nowrap",
              }}
              color="white"
            >
              {isNotFound ? "Not Found" : isEdited ? "Edited" : "New"}
            </Text>
          )}
        </Flex>
      </Flex>
    </Container>
  );
};

export default ClausesListItem;
