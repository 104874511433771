import { Button } from "@mui/material";

interface IGrabButtonProps {
  grab: (doc_id: string, page_index: string, page_id: string) => void;
  document: any;
}

const GrabButton = ({ document, grab }: IGrabButtonProps) => {
  return (
    <Button
      variant="text"
      style={{ width: "100px" }}
      onClick={() => {
        grab(document.doc_id, "", "");
      }}
    >
      Grab
    </Button>
  );
};
export default GrabButton;
