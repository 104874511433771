import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { TableContainer, TableHead } from "@mui/material";
import ExportTableItem from "./Item";
// import Flex from "styled-flex-component";

interface IExportTableProps {
  items: any[];
  itemType: string;
  exportIds: string[];
  setExportIds: (ids: string[]) => void;
}

export default function ExportTable({
  items,
  itemType,
  exportIds,
  setExportIds,
}: IExportTableProps) {
  return (
    <TableContainer>
      <Table>
        <TableHead></TableHead>
        <TableBody>
          {items.map((item: any, index) => (
            <ExportTableItem
              key={index}
              item={item}
              exportIds={exportIds}
              setExportIds={(ids) => setExportIds(ids)}
              itemType={itemType}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
