import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { Text } from "../../UI/Typography";
import { useNavigate } from "react-router-dom";
import DocumentTypeChip from "../../UI/DocumentTypeChip/DocumentTypeChip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Flex from "styled-flex-component";
import { capitalize } from "lodash";
import { useState } from "react";
import SubmitButton from "../SubmitButton";
import PageParentItem from "./PageParentItem";

interface Props {
  document: any;
  index: number;
  grab: (doc_id: string, page_index: string, page_id: string) => void;
  unassign: (assignment_id: string, parent_assignment_id: string) => void;
  isUnassigned: boolean;
  isAssignedToOthers: boolean;
  refetch: () => void;
  updateStatus: (assignment_id: string) => void;
}

export default function PageItem({
  document,
  index,
  grab,
  unassign,
  isUnassigned,
  isAssignedToOthers,
  refetch,
  updateStatus,
}: Props) {
  const navigate = useNavigate();
  const [savingId, setSavingId] = useState("");
  const [grabbingIndex, setGrabbingIndex] = useState("");
  const [grabbingIndexDocId, setGrabbingIndexDocId] = useState("");

  return (
    <div key={index}>
      <Accordion elevation={0} key={document.doc_id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ padding: 0, height: "60px" }}
        >
          <PageParentItem
            document={document}
            index={index}
            grab={grab}
            unassign={unassign}
            isUnassigned={isUnassigned}
            isAssignedToOthers={isAssignedToOthers}
            updateStatus={updateStatus}
            refetch={refetch}
          />
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          {document.pages.map((page, index) => (
            <Flex
              alignCenter
              key={page.page_index}
              style={{
                background: page.page_type
                  ? "#e8f5e9"
                  : index % 2 == 0
                    ? "whitesmoke"
                    : "white",
                height: "60px",
                paddingLeft: 20,
                justifyContent: isAssignedToOthers
                  ? "flex-start"
                  : "space-between",
              }}
            >
              {/* Page Details */}
              <Flex
                style={{
                  width: "100%",
                }}
              >
                {/* Page Number */}
                <Flex style={{ width: "19%" }}>
                  <Text customFontSize={1}>Page {page.page_index}</Text>
                </Flex>
                {/* Page Type */}
                <Flex justifyCenter style={{ width: "15%" }}>
                  {page.page_type && (
                    <DocumentTypeChip label={page.page_type} isPrimary={true} />
                  )}
                </Flex>
                {/* Date Uploaded - empty */}
                <Flex
                  justifyCenter
                  style={{
                    width: "10%",
                    minWidth: "100px",
                  }}
                >
                  {" "}
                </Flex>
                {/* Status */}
                <Flex
                  style={{
                    width: "10%",
                    color: page.status != "pending" ? "green" : "black",
                  }}
                >
                  <Text style={{ textAlign: "center", width: "100%" }}>
                    {capitalize(page.status)}
                  </Text>
                </Flex>
                {/* Assignee */}
                {isAssignedToOthers && (
                  <Flex
                    justifyCenter
                    style={{
                      width: "15%",
                      minWidth: "100px",
                    }}
                  >
                    <Tooltip title={page.user_id}>
                      <div
                        style={{
                          width: "60%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textWrap: "nowrap",
                        }}
                      >
                        <Text>{page.user_id}</Text>
                      </div>
                    </Tooltip>
                  </Flex>
                )}
                {/* Tags - empty */}
                <Flex
                  justifyCenter
                  style={{
                    width: "10%",
                    minWidth: "150px",
                  }}
                >
                  {" "}
                </Flex>
                {/* Actions */}
                <Flex
                  justifyEnd
                  style={{
                    width: isAssignedToOthers ? "20%" : "36%",
                  }}
                >
                  {!isAssignedToOthers && (
                    <Flex justifyEnd key={index}>
                      {page.status == "classified" ? (
                        <Flex style={{ gap: "10px" }}>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              navigate(`type/${document.doc_id}`, {
                                state: {
                                  packetData: page,
                                },
                              });
                            }}
                            style={{ width: "135px" }}
                          >
                            {/* Reclassify */}
                            Reclassify
                          </Button>
                          <SubmitButton
                            assignment_id={page.assignment_id}
                            savingId={savingId}
                            setSavingId={setSavingId}
                            refetch={refetch}
                            updateStatus={updateStatus}
                          />
                        </Flex>
                      ) : (
                        <Flex justifyEnd>
                          {!isUnassigned && (
                            <Button
                              variant="text"
                              onClick={() => {
                                if (
                                  document.doc_id == grabbingIndexDocId &&
                                  page.page_index == grabbingIndex
                                ) {
                                  setGrabbingIndex("");
                                  setGrabbingIndexDocId("");
                                }
                                unassign(
                                  page.assignment_id,
                                  document.assignment_id
                                );
                              }}
                            >
                              Unassign
                            </Button>
                          )}
                          <Button
                            variant={isUnassigned ? "text" : "outlined"}
                            style={{ width: "135px" }}
                            disabled={
                              isUnassigned &&
                              grabbingIndex == page.page_index &&
                              grabbingIndexDocId == document.doc_id
                            }
                            onClick={() => {
                              if (isUnassigned) {
                                setGrabbingIndex(page.page_index);
                                setGrabbingIndexDocId(document.doc_id);
                                grab(
                                  document.doc_id,
                                  page.page_index,
                                  page.page_id
                                );
                              } else
                                navigate(`type/${document.doc_id}`, {
                                  state: {
                                    packetData: {
                                      ...page,
                                      parentDoc: document,
                                    },
                                  },
                                });
                            }}
                          >
                            {isUnassigned ? (
                              grabbingIndex == page.page_index &&
                              grabbingIndexDocId == document.doc_id ? (
                                <CircularProgress
                                  style={{
                                    color: "#3f51b5",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              ) : (
                                "Grab"
                              )
                            ) : (
                              "Classify"
                            )}
                          </Button>
                        </Flex>
                      )}
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
