import React, { createContext, useState } from "react";
import { InsightType } from "../../../enums/InsightType";
import {
  IEditSelectedClause,
  ISelectedEntity,
  ISelectedRegion,
} from "../../../components/GetInsights/helpers/types";
export interface IChatSource {
  page: number;
}

export interface IChat {
  isFromMe: boolean;
  message: string;
  sources?: IChatSource[];
}

export type FeedbackReviewContextType = {
  pageTypeOptions: any;
  docTypeOptions: any;
  feedback: any;
  pages: any;
  feedbacks: any;
  loadingInsights: any;
  insights: any;
  isDocumentLoaded: boolean;
  selectedInsight: string | ISelectedEntity | undefined;
  selectedInsightId: string;
  selectedInsightType: InsightType;
  editSelectedClause: IEditSelectedClause | undefined;
  selectedRegion: ISelectedRegion | undefined;
  selectedSourcePageIndex: number;
  textMatchIndex: number;
  chats: IChat[];
  currentPage: number;
  numPages: number;
  fileUrl: string;
  currentMatchIndex: number;
  matchCase: boolean;
  matchWord: boolean;
  annotated: [];
  backToPageTrigger: number;
  setSelectedInsight: (insight: string | ISelectedEntity | undefined) => void;
  setSelectedInsightId: (insightId: string) => void;
  setSelectedInsightType: (type: InsightType) => void;
  setEditSelectedClause: (clause: IEditSelectedClause | undefined) => void;
  setSelectedRegion: (region: ISelectedRegion | undefined) => void;
  setSelectedSourcePageIndex: (pageIndex: number) => void;
  setTextMatchIndex: (index: number) => void;
  setChats: (chats: IChat[]) => void;
  setCurrentPage: (page: number) => void;
  setNumPages: (pages: number) => void;
  setIsDocumentLoaded: (val: boolean) => void;
  setCurrentMatchIndex: (index: number) => void;
  setMatchCase: (matchCase: boolean) => void;
  setMatchWord: (matchWord: boolean) => void;
  setAnnotated: (annotated: []) => void;
  setBackToPageTrigger: (trigger: number) => void;
};

export const FeedbackReviewContext =
  createContext<FeedbackReviewContextType | null>(null);

interface FeedbackReviewProviderProps {
  children: React.ReactNode;
  fileUrl: string;
  pageTypeOptions?: any;
  docTypeOptions?: any;
  feedback?: any;
  pages?: any;
  feedbacks?: any;
  loadingInsights?: any;
  insights?: any;
}

const FeedbackReviewProvider = ({
  children,
  fileUrl,
  pageTypeOptions,
  docTypeOptions,
  feedback,
  pages,
  feedbacks,
  loadingInsights,
  insights,
}: FeedbackReviewProviderProps) => {
  const [selectedInsight, setSelectedInsight] = useState<
    string | ISelectedEntity | undefined
  >();
  const [selectedInsightType, setSelectedInsightType] = useState<InsightType>(
    InsightType.CLAUSE
  );
  const [editSelectedClause, setEditSelectedClause] = useState<
    IEditSelectedClause | undefined
  >();
  const [selectedRegion, setSelectedRegion] = useState<
    ISelectedRegion | undefined
  >(undefined);
  const [selectedSourcePageIndex, setSelectedSourcePageIndex] =
    useState<number>(0);
  const [chats, setChats] = useState<IChat[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [numPages, setNumPages] = useState<number>(0);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState<boolean>(false);
  const [textMatchIndex, setTextMatchIndex] = useState<number>(0);
  const [currentMatchIndex, setCurrentMatchIndex] = useState<number>(0);
  const [matchCase, setMatchCase] = useState(false);
  const [matchWord, setMatchWord] = useState(false);
  const [annotated, setAnnotated] = useState<any>([]);
  const [backToPageTrigger, setBackToPageTrigger] = useState<number>(0);
  const [selectedInsightId, setSelectedInsightId] = useState("");

  return (
    <FeedbackReviewContext.Provider
      value={{
        fileUrl,
        pageTypeOptions,
        docTypeOptions,
        feedback,
        pages,
        feedbacks,
        insights,
        loadingInsights,
        isDocumentLoaded,
        selectedInsight,
        selectedInsightId,
        selectedInsightType,
        editSelectedClause,
        selectedRegion,
        selectedSourcePageIndex,
        textMatchIndex,
        chats,
        currentPage,
        numPages,
        currentMatchIndex,
        matchCase,
        matchWord,
        annotated,
        backToPageTrigger,
        setSelectedInsight,
        setSelectedInsightId,
        setIsDocumentLoaded,
        setSelectedInsightType,
        setEditSelectedClause,
        setSelectedRegion,
        setSelectedSourcePageIndex,
        setTextMatchIndex,
        setChats,
        setCurrentPage,
        setNumPages,
        setCurrentMatchIndex,
        setMatchCase,
        setMatchWord,
        setAnnotated,
        setBackToPageTrigger,
      }}
    >
      {children}
    </FeedbackReviewContext.Provider>
  );
};

export default FeedbackReviewProvider;
