import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useContext, useState } from "react";
import {
  GetInsightsContext,
  GetInsightsContextType,
} from "../../../pages/GetInsights/context/getInsightsContext";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { deleteDocumentInsight } from "../../../api/annotate.api";
import { toast } from "react-toastify";
import Flex from "styled-flex-component";
import { Delete } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IDeleteButtonProps {
  refetchInsights: () => void;
}

const DeleteButton = ({ refetchInsights }: IDeleteButtonProps) => {
  const {
    selectedInsight,
    selectedInsightId,
    packetData,
    textMatchIndex,
    currentInsight,
    setSelectedInsight,
    setSelectedInsightId,
    setTextMatchIndex,
  } = useContext(GetInsightsContext) as GetInsightsContextType;

  const [open, setOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const hasMultipleAnswers = currentInsight.answers.length > 1;

  const deleteInsight = () => {
    setIsDeleting(true);
    try {
      const insightToDelete = currentInsight.answers[textMatchIndex].insight_id;
      deleteDocumentInsight(packetData.doc_id, insightToDelete).then(() => {
        refetchInsights();
        setTextMatchIndex(textMatchIndex == 0 ? 0 : textMatchIndex - 1);
        toast.success(`${selectedInsight} deleted successfully.`);
        setOpen(false);
        setIsDeleting(false);
      });
    } catch (e: any) {
      toast.error(e?.detail ?? "Something went wrong");
      setIsDeleting(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsDeleting(false);
    setSelectedInsight(selectedInsight);
    setSelectedInsightId(selectedInsightId);
  };

  return (
    <React.Fragment>
      <Tooltip title="Delete Insight">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleClickOpen();
          }}
        >
          <Delete />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        sx={{
          ".MuiPaper-root": {
            padding: 2,
          },
        }}
      >
        <DialogTitle>{`Confirm Delete`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Are you sure you want to delete ${hasMultipleAnswers ? `match ${textMatchIndex + 1} of ` : ""}${selectedInsight}? This ${hasMultipleAnswers ? "definition" : "insight"} will be deleted immediately. You can't undo this action.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Flex justifyEnd style={{ gap: "10px" }} alignCenter>
            {isDeleting ? (
              <CircularProgress
                style={{
                  color: "#3f51b5",
                  width: "31px",
                  height: "31px",
                }}
              />
            ) : (
              <>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsDeleting(true);
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteInsight();
                  }}
                  color="primary"
                  variant="contained"
                >
                  Yes, delete
                </Button>
              </>
            )}
          </Flex>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DeleteButton;
