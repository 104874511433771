const defaultTheme = {
  name: "Default Theme",
  primary: "#038aff",
  primaryDark: "#0d1832",
  textDark: "#1F313D",
  secondary: "rgb(0,177,120)",
  background: "#f7fafd",
  whiteColor: "#fff",
  error: "#d32f2f",
  success: "#10B981",
  info: "#1d7cc7",
  warning: "#ffa41e",
  highlight: "rgba(3, 138, 255, 0.2)",
  editedHighlight: "rgba(16, 185, 129, 0.4)",
  lightgreen: "#DDF2D1",
};

export const Theme = { ...defaultTheme };
