import { Switch } from "@mui/material";
import { Text } from "../Typography";
import Flex from "styled-flex-component";

interface ITestDataToggleProps {
  isShowingTestData: boolean;
  setIsShowingTestData: (isShowingTestData: boolean) => void;
}

const TestDataToggle = ({
  isShowingTestData,
  setIsShowingTestData,
}: ITestDataToggleProps) => {
  return (
    <Flex alignCenter>
      <Switch
        checked={isShowingTestData}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setIsShowingTestData(event.target.checked)
        }
      />
      <Text color="gray">Show test files</Text>
    </Flex>
  );
};

export default TestDataToggle;
