import Flex from "styled-flex-component";
import Container from "../../components/UI/Common/Container";
import { useEffect, useState } from "react";
import GetInsightsLayoutStyled from "../../components/GetInsights/Layout/Layout.styled";
import { Panel, PanelGroup } from "react-resizable-panels";
import { useLocation } from "react-router-dom";
import { getDocumentUrl } from "../../api/files.api";
import FeedbackReviewProvider from "./context/feedbackReviewContext";
import Header from "../../components/FeedbackReview/Header/Header";
// import PDFViewerPageIndicator from "../../components/GetInsights/PDFViewerPageIndicator";
import FeedbackReviewPDFViewer from "../../components/FeedbackReview/FeedbackReviewPDFViewer";

const FeedbackReview = () => {
  const location = useLocation();
  const { feedback } = location.state;
  const [fileUrl, setFileUrl] = useState<any>("");

  useEffect(() => {
    getDocumentUrl(feedback.doc_id).then((res) => {
      setFileUrl(res);
    });
  }, []);

  return (
    <FeedbackReviewProvider fileUrl={fileUrl} feedback={feedback}>
      <GetInsightsLayoutStyled>
        <Header />
        <Container topInnerSpacing={4}>
          <Flex justifyBetween full>
            <PanelGroup autoSaveId="sidebar" direction="horizontal">
              <Panel order={2}>
                <Container
                  topInnerSpacing={0}
                  bottomInnerSpacing={1.0}
                  leftInnerSpacing={0}
                  rightInnerSpacing={1.6875}
                >
                  <FeedbackReviewPDFViewer fileUrl={fileUrl} />
                  {/* <PDFViewerPageIndicator /> */}
                </Container>
              </Panel>
            </PanelGroup>
          </Flex>
        </Container>
      </GetInsightsLayoutStyled>
    </FeedbackReviewProvider>
  );
};

export default FeedbackReview;
