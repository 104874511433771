import axios from "axios";
import { getUserToken, removeUserSessionData } from "../utils/userSession";
import { supabase } from "../utils/supabase";

const instance = axios.create();

const interceptor = async (config) => {
  return await getUserToken().then(async (token) => {
    const tokenDetails = await supabase.auth.getUser(token);
    if (!tokenDetails.data.user) {
      await supabase.auth.signOut();
      removeUserSessionData();
      location.href = "/login";
    }
    const backendBaseURL = process.env.REACT_APP_LITH_API_BASE_URL;
    config.baseURL = backendBaseURL ? backendBaseURL : config.baseURL;
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

instance.interceptors.request.use(interceptor);

export default instance;
